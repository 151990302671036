import { PageHeader } from "../../../UIPalette/components/PageHeader/PageHeader";
import { useTranslation } from "react-i18next";
import { RowMenuItem, Table, TableHeader } from "../../../UIPalette/components/Table/Table";
import { OrderByDirection, RoleKeys } from "core/Constants";
import { ListClientsOrderBy } from "./ListClientsOrderBy";
import { useState } from "react";
import Input from "@brighthr/component-input";
import {
  useDeleteClientFunctionRequest,
  useListClientsFunctionRequest
} from "shared/request/myHealthyAdvantageApi";
import { useDebounce } from "shared/core/hooks/useDebounce";
import ErrorAlert from "shared/UI/Alerts/ErrorAlert";
import { useNavigate } from "react-router-dom";
import StylableButton from "shared/UI/Buttons/StylableButton";
import { toastNotify } from "shared/UI/Toaster/Toaster";
import "shared/extensions/string";
import { useAdminUserContext } from "core/state/AdminUserContext";
import { ConfirmationModal } from "shared/UI/Modal/ConfirmationModal/ConfirmationModal";

export const ViewClients = () => {
  const { t } = useTranslation();
  const navigateHook = useNavigate();
  const { trigger: deleteClientFunctionRequest } = useDeleteClientFunctionRequest();
  const [searchFilter, setSearchFilter] = useState("");
  const [ordering, setOrdering] = useState({
    column: ListClientsOrderBy.CreatedDateUtc,
    direction: OrderByDirection.Descending
  });
  const [paging, setPaging] = useState({ current: 1, size: 10 });
  const debouncedSearchFilter = useDebounce(searchFilter, 500);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedClientIndex, setSelectedClientIndex] = useState(0);
  const { isAuthorised } = useAdminUserContext();
  const {
    data: clientsResponse,
    error,
    mutate,
  } = useListClientsFunctionRequest({
    filter: debouncedSearchFilter,
    page: paging.current,
    perPage: paging.size,
    orderBy: ordering.column,
    orderByDirection: ordering.direction,
  });

  const tableHeaders: TableHeader[] = [
    { text: t("clientManagement.table.nameHeading"), orderByValue: ListClientsOrderBy.Name },
    { text: t("clientManagement.table.codeHeading"), orderByValue: ListClientsOrderBy.Code },
    {
      text: t("clientManagement.table.creationDateHeading"),
      orderByValue: ListClientsOrderBy.CreatedDateUtc,
      defaultOrderDirection: OrderByDirection.Descending,
    },
  ];

  const mapTableData = () =>
      clientsResponse?.records?.map(({ name, code, ...rest }) => {
        return {
          name,
          code,
          createdDate: String.formatDateTime(rest.createdDate),
        };
      });

  const onDeleteClient = async (rowIndex: number) => {
    setSelectedClientIndex(rowIndex);
    setShowDeleteModal(true);
  };

  const onEditClient = async (rowIndex: number) => {
    navigateHook("/clientmanagement/edit", { state: clientsResponse!.records![rowIndex] });
  };

  const buildRowMenuItems = () => {
    let rowMenuItems: RowMenuItem[] = [];

    if (isAuthorised({ role: RoleKeys.HaAdmin, permissions: ["delete-clients"] })) {
      rowMenuItems.push({
        title: t("table.deleteTitle"),
        ariaLabel: t("table.ariaDeleteRow"),
        onClick: (rowIndex: number) => onDeleteClient(rowIndex),
      });
    }

    if (isAuthorised({ role: RoleKeys.HaAdmin, permissions: ["edit-clients"] })) {
      rowMenuItems.push({
        title: t("table.editTitle"),
        ariaLabel: t("table.ariaEditRow"),
        onClick: (rowIndex: number) => onEditClient(rowIndex),
      });
    }

    return rowMenuItems;
  };

  const deleteClient = async () => {
    try {
      const clientId = clientsResponse!.records![selectedClientIndex].id!;
      await deleteClientFunctionRequest({ query: { id: clientId } });
      await mutate();
      toastNotify(t("clientDelete.toastMessage"), "success");
    } catch {
      toastNotify(t("clientDelete.requestErrorMessage"), "error");
    } finally {
      setShowDeleteModal(false);
    }
  };

  return (
      <>
        <PageHeader title={t("clientManagement.title")} subtitle={t("clientManagement.subtitle")} />
        <div className="w-2/5 my-2 content-center">
          <Input
              aria-label={t("clientManagement.ariaSearch")}
              onChange={(a) => {
                setPaging({ ...paging, current: 1 });
                setSearchFilter(a.target.value);
              }}
              rightIconName="search"
              placeholder={t("clientManagement.searchPlaceholder")}
          ></Input>
        </div>
        <Table
            headers={tableHeaders}
            dataRows={mapTableData() ?? []}
            orderedByColumn={ordering.column}
            orderedDirection={ordering.direction}
            currentPage={paging.current}
            pageSize={paging.size}
            totalDataCount={clientsResponse?.metadata?.totalCount ?? 0}
            onOrderingChange={setOrdering}
            onPagingChange={setPaging}
            actionButtons={
              <StylableButton className="rounded-button" color="primary" text={"+"}
                              onClick={() => navigateHook("/clientmanagement/create")} />
            }
            rowMenuItems={buildRowMenuItems()}
        />
        {showDeleteModal && (
            <ConfirmationModal
                title={t("clientDelete.modalTitle")}
                message={t("clientDelete.modalMessage", { clientName: clientsResponse!.records![selectedClientIndex].name })}
                cancelButtonText={t("clientDelete.modalDismissTitle")}
                confirmButtonText={t("clientDelete.modalConfirmTitle")}
                onCancel={() => {
                  setShowDeleteModal(false);
                }}
                onConfirm={deleteClient}
            />
        )}
        {error && <ErrorAlert content={t("clientManagement.requestErrorMessage")} />}
      </>
  );
};
