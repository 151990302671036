import { t } from "i18next";
import { ReactElement } from "react";
import { RoundedCard } from "shared/UI/RoundedCard/RoundedCard";
import { ReactComponent as TrashButton } from "shared/assets/Icons/trash-button-mono.svg";
import { ValueCallback } from "shared/core/types/callbacks";

type TargetGroupPreviewCardProps = {
  name: string;
  clients: { id: string; name: string }[];
  onRemove: ValueCallback<string>;
};

export const TargetGroupPreviewCard = ({ name, clients, onRemove }: TargetGroupPreviewCardProps): ReactElement => {
  return (
    <RoundedCard radiusStyle="rounded-lg min-h-full">
      <p className="font-bold">{t("targetGroups.targetGroup.preview.header")}</p>
      <p className="pl-2 my-2">
        <strong>{t("targetGroups.targetGroup.preview.name")}</strong>
        {name}
      </p>
      <strong className="pl-2 mt-2">{t("targetGroups.targetGroup.preview.targetedClients")}</strong>
      <ul className="pl-10 list-disc">
        {clients.map((client, index) => (
          <li key={index}>
            <div className="flex justify-between items-center mb-2">
              <p className="left-0">{client.name}</p>
              <TrashButton
                className="mr-5"
                height={25}
                role="button"
                aria-label={t("targetGroups.targetGroup.removeTargetedClient", { client: client.name })}
                onClick={() => onRemove(client.id)}
              />
            </div>
          </li>
        ))}
      </ul>
    </RoundedCard>
  );
};
