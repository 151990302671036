import { CheckboxGrid, CheckboxGridItem } from "../../../../UIPalette/components/CheckboxGrid/CheckboxGrid";
import {
  ClientResponseModel,
  DivisionModel,
  useListLocaleFunctionRequest,
  useListModulesFunctionRequest,
} from "shared/request/myHealthyAdvantageApi";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import ClientDivisionsEditor from "../ClientDivisionsEditor/ClientDivisionsEditor";
import { ClientLogo } from "shared/UI/ClientLogo/ClientLogo";
import { ConfirmationModal } from "shared/UI/Modal/ConfirmationModal/ConfirmationModal";
import ErrorAlert from "shared/UI/Alerts/ErrorAlert";
import { Form } from "../../../../UIPalette/components/Form/Form";
import { Hideable } from "shared/UI/Hideable/Hideable";
import Input from "@brighthr/component-input";
import { LocaleSelect } from "shared/components/LocaleSelect";
import { PageHeader } from "../../../../UIPalette/components/PageHeader/PageHeader";
import ViewLoading from "shared/UI/Spinners/ViewLoading";
import { clientModuleDependencies } from "../../View/clientModules";
import { useDefaultLocaleId } from "shared/core/hooks/useDefaultLocaleList";
import { useTranslation } from "react-i18next";
import { removeSpaces } from "shared/core/utils/string";
import helplineNumbersJson from "shared/assets/json/helplineNumbers.json";

export type Inputs = {
  name: string;
  phoneNumber: string;
  code: string;
  schemeNumber: string;
  userLimit: number;
  localeId: string;
  modules: CheckboxGridItem[];
  divisions: DivisionModel[];
};

const viewClientsPath = "/clientmanagement";

interface ClientFormProps {
  onSubmit: (clientData: Inputs) => void;
  name: "editClient" | "createClient";
}

type HelplineNumber = { country: string; number: string };

const helplineNumbers: HelplineNumber[] = helplineNumbersJson;

const isHelplineNumberStringValid = (inputString: string): boolean => {
  let normalizedInput = removeSpaces(inputString);
  helplineNumbers.forEach(({ number }) => {
    const normalisedHelplineNumber = removeSpaces(number);
    if (normalizedInput.includes(normalisedHelplineNumber)) {
      normalizedInput = normalizedInput.replace(normalisedHelplineNumber, "");
    }
  });
  if (normalizedInput.replace(/[ /,]+/g, "") === "") return true;
  return false;
};

export const ClientForm = ({ onSubmit, name }: ClientFormProps) => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigateHook = useNavigate();
  const defaultLocaleId = useDefaultLocaleId();
  let clientData: ClientResponseModel = state;
  const { data: moduleList, isLoading: isListModulesLoading } = useListModulesFunctionRequest();
  const { data: localeList, isLoading: isListLocaleLoading } = useListLocaleFunctionRequest();

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [locale, setLocale] = useState(undefined);
  const isClientEdit = name === "editClient";

  const {
    control,
    getValues,
    reset,
    trigger,
    formState: { errors, dirtyFields },
  } = useForm<Inputs>({
    defaultValues: isClientEdit
      ? {
          name: clientData.name,
          phoneNumber: clientData.phoneNumber,
          code: clientData.code,
          schemeNumber: clientData.schemeNumber,
          localeId: clientData.localeId,
          userLimit: clientData.userLimit,
          divisions: clientData.divisions ?? [],
          modules: [],
        }
      : {
          userLimit: 0,
          divisions: [],
          modules: [],
          localeId: defaultLocaleId,
        },
  });

  const handleLocaleSelect = (e: any, value: any, onChange: any) => {
    setLocale(value);
    return onChange(e);
  };

  const handleSubmitForm = () => {
    const clientFormValues = getValues();
    onSubmit(clientFormValues);
  };

  const onConfirmSubmit = () => {
    const clientFormValues = getValues();
    if (dirtyFields?.modules) {
      setShowConfirmationModal(true);
    } else {
      onSubmit(clientFormValues);
    }
  };

  useEffect(() => {
    if (getValues("modules").length === 0 && moduleList?.modules) {
      reset({
        modules: moduleList.modules.map((module) => ({
          id: module.id,
          key: module.key,
          displayText: t<string>(`clientModules.${module.key}`),
          selected: isClientEdit ? clientData.modules?.some((clientModule) => clientModule.id === module.id) : false,
        })),
      });
    }
  }, [reset, moduleList, getValues, t, clientData?.modules, isClientEdit]);

  useEffect(() => {
    if (!isClientEdit) {
      if (!getValues("localeId")) {
        reset({
          ...getValues(),
          localeId: defaultLocaleId,
        });
      }
    }
  }, [defaultLocaleId, reset, getValues, isClientEdit]);

  if (isListModulesLoading || isListLocaleLoading) {
    return <ViewLoading label={t("clientForm.loading")} />;
  }

  return (
    <>
      <PageHeader title={t(`${name}.title`)} subtitle={t(`${name}.subtitle`)} />
      {localeList && moduleList ? (
        <div>
          <Form
            className="mt-4"
            onSubmit={isClientEdit ? onConfirmSubmit : handleSubmitForm}
            submitButtonText={t(`${name}.saveButton`)}
            onCancel={() => navigateHook(viewClientsPath)}
            validationTrigger={trigger}
          >
            <div className="content-center w-4/5 mt-4">
              <div className="flex flex-row justify-between mb-4">
                <div className="w-1/2">
                  <Controller
                    name={"name"}
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { value, onChange } }) => (
                      <>
                        <Input
                          defaultValue={value}
                          onChange={onChange}
                          aria-label={t("clientForm.ariaClientName")}
                          placeholder={t("clientForm.clientNamePlaceholder")}
                        />
                        {errors.name && <ErrorAlert content={t("clientForm.clientNameErrorMessage")} />}
                      </>
                    )}
                  />
                </div>
                <div className="w-1/2 ml-4">
                  <Controller
                    name={"phoneNumber"}
                    control={control}
                    rules={{
                      required: true,
                      validate: {
                        isValid: isHelplineNumberStringValid,
                      },
                    }}
                    render={({ field: { value, onChange } }) => (
                      <>
                        <Input
                          defaultValue={value}
                          onChange={onChange}
                          aria-label={t("clientForm.ariaPhoneNumber")}
                          placeholder={t("clientForm.phoneNumberPlaceholder")}
                        />
                        {errors.phoneNumber && <ErrorAlert content={t("clientForm.phoneNumberErrorMessage")} />}
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="flex flex-row justify-between mb-4">
                <div className="w-1/2">
                  <Controller
                    name={"code"}
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { value, onChange } }) => (
                      <>
                        <Input
                          defaultValue={value}
                          onChange={onChange}
                          aria-label={t("clientForm.ariaCode")}
                          placeholder={t("clientForm.codePlaceholder")}
                        />
                        {errors.code && <ErrorAlert content={t("clientForm.codeErrorMessage")} />}
                      </>
                    )}
                  />
                </div>
                <div className="w-1/2 ml-4">
                  <Controller
                    name={"schemeNumber"}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Input
                        defaultValue={value}
                        onChange={onChange}
                        aria-label={t("clientForm.ariaSchemeNumber")}
                        placeholder={t("clientForm.schemeNumberPlaceholder")}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="flex flex-row justify-between mb-4">
                <div className="w-1/2">
                  <Controller
                    name={"userLimit"}
                    control={control}
                    rules={{
                      required: true,
                      min: 1,
                    }}
                    render={({ field: { value, onChange } }) => (
                      <>
                        <Input
                          defaultValue={value}
                          type="number"
                          onChange={onChange}
                          aria-label={t("clientForm.ariaUserLimit")}
                          placeholder={t("clientForm.userLimitPlaceholder")}
                        />
                        {errors.userLimit && <ErrorAlert content={t("clientForm.userLimitErrorMessage")} />}
                      </>
                    )}
                  />
                </div>
                <div className="w-1/2 ml-4">
                  <Controller
                    name={"localeId"}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <LocaleSelect
                        value={value}
                        onChange={(e) => handleLocaleSelect(e, value, onChange)}
                        height="h-[42px] font-semibold text-neutral-700"
                        ariaLabel={t("clientForm.ariaLocaleSelect")}
                      />
                    )}
                  />
                </div>
              </div>

              <Controller
                control={control}
                name="modules"
                render={({ field: { value, onChange } }) => {
                  return getValues("modules").length === 0 ? (
                    <></>
                  ) : (
                    <CheckboxGrid
                      items={value}
                      dependencies={clientModuleDependencies}
                      headingText={t("clientModules.heading")}
                      onChange={(state) => onChange(state)}
                    />
                  );
                }}
              />

              <div className="flex flex-row flex-grow">
                <div className="w-1/2">
                  <Controller
                    control={control}
                    name="divisions"
                    render={({ field: { value: divisions, onChange } }) => {
                      return (
                        <ClientDivisionsEditor
                          defaultLocale={getValues("localeId") || locale || clientData?.localeId}
                          initialDivisions={divisions}
                          onChange={(divisions) => onChange(divisions)}
                        />
                      );
                    }}
                  />
                </div>
                {clientData && (
                  <Hideable hidden={clientData.customLogo.length === 0}>
                    <div className="w-1/2 ml-4">
                      <h4 className="mt-8 mb-4">{t("editClient.logo.heading")}</h4>
                      <ClientLogo clientCode={clientData.code} imageFilename={clientData.customLogo} alt={t("editClient.logo.alt")} />
                    </div>
                  </Hideable>
                )}
              </div>
            </div>
          </Form>

          {showConfirmationModal && (
            <ConfirmationModal
              title={t("editClient.confirmation.modalTitle")}
              message={t("editClient.confirmation.modalMessage", { name: getValues("name") })}
              cancelButtonText={t("editClient.confirmation.modalDismissTitle")}
              confirmButtonText={t("editClient.confirmation.modalConfirmTitle")}
              onCancel={() => {
                setShowConfirmationModal(false);
              }}
              onConfirm={handleSubmitForm}
            />
          )}
        </div>
      ) : (
        <div className="w-full mt-4">
          <ErrorAlert content={t("clientForm.requestErrorBody")} />
        </div>
      )}
    </>
  );
};
