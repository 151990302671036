import "shared/extensions/date";

import { ChartFilters } from "../ChartProps";
import { ReportType } from "../../Reports/ReportConstants";
import { useGetReportFunctionRequest } from "shared/request/myHealthyAdvantageApi";

export type useGetReportProps = {
  reportType: ReportType;
  filters: ChartFilters;
};
export const useGetReport = ({ reportType, filters }: useGetReportProps) => {
  const dateToString = Date.fromDateOnly(filters.dateTo)?.toDateOnlyString()!;
  const dateFromString = Date.fromDateOnly(filters.dateFrom)?.toDateOnlyString()!;

  const isPreferencesRequest = reportType === ReportType.Preferences;

  const query = {
    reportType: reportType.toString(),
    dateFrom: dateFromString,
    dateTo: dateToString,
    groupBy: filters.groupBy?.toString(),
    targetGroupClientIds: filters.targetGroupClientIds,
    clientId: filters.clientId,
    divisionId: filters.divisionId,
    ...(isPreferencesRequest && { localeId: filters.localeId }),
  };

  return useGetReportFunctionRequest(query);
};
