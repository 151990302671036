import { useVerifyAdminUserFunctionRequest } from "shared/request/myHealthyAdvantageApi";
import { Navigate, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ErrorAlert from "shared/UI/Alerts/ErrorAlert";
import ViewLoading from "shared/UI/Spinners/ViewLoading";
import { useAuth, UseAuthResult } from "core/auth/useAuth";
import { DestinationState } from "shared/core/state/destinationState";
import Button from "@brighthr/component-button";
import { useEffect } from "react";
import { useAdminUserContext } from "core/state/AdminUserContext";

export const Auth = () => {
  const { t } = useTranslation();
  const navigateHook = useNavigate();
  const { saveUser, needsToCompleteAdditionalInfo } = useAdminUserContext();
  const auth: UseAuthResult = useAuth();
  const authenticated = !auth.hasError && !auth.isLoading && auth.isAuthenticated;

  const {
    data: adminUser,
    isLoading,
    error,
  } = useVerifyAdminUserFunctionRequest(
    { shouldFetch: authenticated },
    {
      headers: { Authorization: `Bearer ${auth.token}` },
    }
  );

  useEffect(() => {
    if (adminUser) {
      saveUser(adminUser);
    }
  }, [adminUser, saveUser]);

  if (!authenticated) {
    return displayAuthenticationProgress(auth, t, navigateHook);
  }

  const verifyAdminUserFailed = error !== undefined;
  if (verifyAdminUserFailed) {
    auth.signoutRedirect();

    return (
      <>
        <ErrorAlert title={t("login.error.title")} content={t("login.error.body")} />
        <Button text={t("login.error.button")} onClick={() => navigateHook("/")} />
      </>
    );
  }

  if (isLoading || auth.isLoading || !adminUser) {
    return <ViewLoading label={t("login.checkingPermissions")} />;
  }

  if (needsToCompleteAdditionalInfo(adminUser)) {
    return <Navigate to="/additional-info" />;
  }
  return <Navigate to={DestinationState.get()} />;
};

function displayAuthenticationProgress(auth: UseAuthResult, t: Function, navigate: Function) {
  if (auth.isLoading) {
    return <ViewLoading label={t("login.loading")} />;
  }

  if (auth.hasError) {
    return (
      <>
        <ErrorAlert title={t("login.error.title")} content={t("login.error.body")} />
        <Button text={t("login.error.button")} onClick={() => navigate("/")} />
      </>
    );
  }

  if (!auth.isAuthenticated) {
    auth.signinRedirect();
    return <></>;
  }

  throw new Error(`Unexpected auth state: ${JSON.stringify(auth, null, 4)}`);
}
