enum RoleKeys {
  HaAdmin = "ha-admin",
  ClientAdmin = "client-admin",
}

enum Roles {
  HaAdmin = "9414D817-98DB-4E55-8518-7E17A589719F",
  ClientAdmin = "A43725CB-3CA7-4FFF-82BA-2C1ED0F958FA",
}

enum OrderByDirection {
  Ascending,
  Descending,
}

export { Roles, RoleKeys, OrderByDirection };
