import { CreateClientCommand, DivisionResponseModel, useCreateClientFunctionRequest } from "shared/request/myHealthyAdvantageApi";

import { CheckboxGridItem } from "../../../../UIPalette/components/CheckboxGrid/CheckboxGrid";
import { ClientForm } from "../../Components/ClientForm/ClientForm";
import ErrorAlert from "shared/UI/Alerts/ErrorAlert";
import { getServerErrorMessage } from "shared/utils/serverError";
import { toastNotify } from "shared/UI/Toaster/Toaster";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export type Inputs = {
  name: string;
  phoneNumber: string;
  code: string;
  schemeNumber: string;
  userLimit: number;
  localeId: string;
  divisions: DivisionResponseModel[];
  modules: CheckboxGridItem[];
};

const viewClientsPath = "/clientmanagement";

export const CreateClient = () => {
  const { t } = useTranslation();
  const navigateHook = useNavigate();
  const { trigger: createClientRequest } = useCreateClientFunctionRequest({});

  const [createClientErrorMessage, setCreateClientErrorMessage] = useState<string | null>(null);

  const onSubmit = async (clientDetails: Inputs) => {
    setCreateClientErrorMessage("");

    const selectedModules = clientDetails.modules.filter((module) => module.selected).map((item) => item.id);

    const command: CreateClientCommand = {
      ...clientDetails,
      modules: selectedModules,
      userLimit: Number(clientDetails.userLimit),
      divisions: clientDetails.divisions,
      localeId: clientDetails.localeId,
    };

    try {
      await createClientRequest({ body: command });
      toastNotify(t("clientForm.successToast", { clientName: clientDetails.name, action: "created" }), "success");
      navigateHook(viewClientsPath);
    } catch (error: any) {
      setCreateClientErrorMessage(getServerErrorMessage(error, t("clientForm.requestErrorMessage", { clientFormAction: "creating" })));
    }
  };

  return (
    <>
      <ClientForm onSubmit={onSubmit} name="createClient" />
      {createClientErrorMessage && <ErrorAlert content={createClientErrorMessage} />}
    </>
  );
};
