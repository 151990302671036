import { Route, Routes } from "react-router-dom";

import { AdditionalInfo } from "./Views/AdditionalInfo/AdditionalInfo";
import { AdminUserContextProvider } from "core/state/AdminUserContext";
import { AppLayout } from "./UIPalette/AppLayout";
import { Auth } from "./Views/Auth/Auth";
import { ClientAdminManagement } from "./Views/ClientAdminManagement/ClientAdminManagement";
import { ClientManagement } from "./Views/ClientManagement/ClientManagement";
import { Dashboard } from "./Views/Dashboard/Dashboard";
import DualBranding from "./Views/DualBranding/DualBranding";
import { HAAdminManagement } from "./Views/HAAdminManagement/HAAdminManagement";
import { ImportClients } from "./Views/ImportClients/ImportClients";
import { PushNotifications } from "./Views/PushNotifications/PushNotifications";
import RouteAuthorisation from "core/auth/routeAuthorisation";
import { TargetGroups } from "./Views/TargetGroups/TargetGroups";
import { Toaster } from "shared/UI/Toaster/Toaster";
import { WisdomUserManagement } from "./Views/WisdomUserManagement/WisdomUserManagement";
import { changeLanguage } from "i18next";
import { protect } from "core/auth/ProtectedRoute";
import { useGetDomainLocale } from "shared/core/hooks/useGetDomainLocale";

export const App = () => {
  const locale = useGetDomainLocale(window.location.hostname);
  changeLanguage(locale);
  return (
    <AdminUserContextProvider>
      <Routes>
        <Route element={protect(<AppLayout />)}>
          <Route index element={protect(<Dashboard />, RouteAuthorisation.dashboard)} />
          <Route path="dashboard" element={protect(<Dashboard />, RouteAuthorisation.dashboard)} />
          <Route path="usermanagement/haadmins/*" element={protect(<HAAdminManagement />, RouteAuthorisation.viewHaAdmins)} />
          <Route path="usermanagement/clientadmins/*" element={protect(<ClientAdminManagement />, RouteAuthorisation.viewClientAdmins)} />
          <Route path="usermanagement/wisdomusers/*" element={protect(<WisdomUserManagement />, RouteAuthorisation.viewWisdomUsers)} />
          <Route path="clientmanagement/*" element={protect(<ClientManagement />, RouteAuthorisation.viewClients)} />
          <Route path="targetgroups/*" element={protect(<TargetGroups />, RouteAuthorisation.targetGroups)} />
          <Route path="importclients" element={protect(<ImportClients />, RouteAuthorisation.importClients)} />
          <Route path="additional-info" element={<AdditionalInfo />} />
          <Route path="dualbranding/" element={protect(<DualBranding />, RouteAuthorisation.dualBranding)} />
          <Route path="pushnotifications" element={protect(<PushNotifications />, RouteAuthorisation.sendPushNotifications)} />
        </Route>
        <Route path="auth" element={<Auth />} />
        <Route path="login" element={<Auth />} />
      </Routes>
      <Toaster />
    </AdminUserContextProvider>
  );
};
