import { CheckboxGridDependency } from "../../../UIPalette/components/CheckboxGrid/CheckboxGrid";
import i18n from "i18next";

export const clientModuleDependencies: CheckboxGridDependency[] = [
  {
    key: "wellbeing-trackers",
    requiredMessage: i18n.t("clientModules.requiredMessages.wellbeing-trackers"),
    dependentKeys: ["achievements", "leaderboards"],
  },
];
