import * as XLSX from "xlsx";
import { formatExportFilename } from "./exportFilenameFormatter";
import { DateOnly, ReportResponseModel, UsersPerClientResponse } from "shared/request/myHealthyAdvantageApi";

export abstract class DataExporter {
  private readonly fileName?: string;

  constructor(fileName?: string) {
    this.fileName = fileName;
  }

  abstract mapData(data: ReportResponseModel | UsersPerClientResponse, workbook: XLSX.WorkBook): void;

  export(data: ReportResponseModel | undefined | UsersPerClientResponse, dateFrom: DateOnly, dateTo: DateOnly): void {
    if (!data) return;
    const workbook = XLSX.utils.book_new();
    this.mapData(data, workbook);
    const fileName = this.fileName ?? formatExportFilename(dateFrom, dateTo);
    XLSX.writeFile(workbook, fileName + ".xlsx", {
      compression: true,
    });
  }
}

export class TimeSeriesLineChartExporter extends DataExporter {
  mapData(data: ReportResponseModel, workbook: XLSX.WorkBook): void {
    data.dataDimensions?.forEach((dimension) => {
      const worksheet = XLSX.utils.json_to_sheet(
        dimension.value!.map((datapoint) => ({
          Date: datapoint.key,
          Count: datapoint.value,
        }))
      );
      XLSX.utils.book_append_sheet(workbook, worksheet, dimension.key);
    });
  }
}

export class PieChartExporter extends DataExporter {
  mapData(data: ReportResponseModel, workbook: XLSX.WorkBook): void {
    const worksheet = XLSX.utils.json_to_sheet(
      data.dataDimensions![0].value!.map((datapoint) => ({
        Group: datapoint.key,
        Count: datapoint.value,
      }))
    );
    XLSX.utils.book_append_sheet(workbook, worksheet, "All");
  }
}

export class BarChartExporter extends DataExporter {
  mapData(data: ReportResponseModel, workbook: XLSX.WorkBook): void {
    data.dataDimensions![0].value!.forEach((datapoint, index) => {
      const worksheet = XLSX.utils.json_to_sheet(
        data.dataDimensions!.map((dimension) => ({
          Group: dimension.key,
          Count: dimension.value![index].value,
        }))
      );
      XLSX.utils.book_append_sheet(workbook, worksheet, datapoint.key);
    });
  }
}

export class UsersPerClientDataExporter extends DataExporter {
  mapData(data: UsersPerClientResponse, workbook: XLSX.WorkBook): void {
    const workSheet = XLSX.utils.json_to_sheet(data.records!);
    XLSX.utils.book_append_sheet(workbook, workSheet, "UsersPerClient");
  }
}
