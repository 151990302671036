import {
  AdminUserResponseModel,
  DivisionResponseModel,
  EditClientAdminUserCommand,
  useEditClientAdminUserFunctionRequest,
  useListAdminPermissionsFunctionRequest,
  useListClientDivisionsFunctionRequest,
} from "shared/request/myHealthyAdvantageApi";
import { CheckboxGrid, CheckboxGridItem } from "../../../UIPalette/components/CheckboxGrid/CheckboxGrid";
import { ClientDivisionSelection, ClientDivisionSelector } from "../../../UIPalette/components/ClientDivisionSelector/ClientDivisionSelector";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { ConfirmationModal } from "shared/UI/Modal/ConfirmationModal/ConfirmationModal";
import ErrorAlert from "shared/UI/Alerts/ErrorAlert";
import { Form } from "../../../UIPalette/components/Form/Form";
import Input from "@brighthr/component-input";
import { PageHeader } from "../../../UIPalette/components/PageHeader/PageHeader";
import { Roles } from "core/Constants";
import { clientAdminPermissionDependencies } from "../../adminUserPermissions";
import { getServerErrorMessage } from "shared/utils/serverError";
import { toastNotify } from "shared/UI/Toaster/Toaster";
import { useTranslation } from "react-i18next";

type Inputs = {
  permissions: CheckboxGridItem[];
  divisions: DivisionResponseModel[];
};

export const EditClientAdmin = () => {
  const { t } = useTranslation();

  const { state } = useLocation();
  const userData = state as AdminUserResponseModel;

  const [errorMessage, setErrorMessage] = useState("");
  const [divisionId, setDivisionId] = useState<string | undefined>(userData?.division?.id);
  const [showPermissionsModal, setShowPermissionsModal] = useState(false);

  const { data: divisionsData, error: divisionError } = useListClientDivisionsFunctionRequest({ clientId: userData.client!.id });
  const { data: permissionsData, error: permissionError } = useListAdminPermissionsFunctionRequest({ role: Roles.ClientAdmin });
  const { trigger: editClientAdminUserTrigger } = useEditClientAdminUserFunctionRequest();

  const navigateHook = useNavigate();

  const {
    control,
    formState: { errors, isDirty },
    getValues,
    reset,
    trigger,
  } = useForm<Inputs>({
    defaultValues: {
      permissions: [],
      divisions: [],
    },
  });

  const onClientDivisionChange = (e: ClientDivisionSelection) => {
    setDivisionId(e?.divisionId);
  };

  const onConfirmSubmit = async () => {
    if (isDirty) {
      setShowPermissionsModal(true);
    } else {
      await onSubmit();
    }
  };

  const onSubmit = async () => {
    setErrorMessage("");

    const selectedPermissions = getValues("permissions").filter((p) => p.selected);
    const permissionsIds = selectedPermissions.map(function (v) {
      return v.id;
    });

    const requestBody: EditClientAdminUserCommand = {
      userId: userData.id,
      permissions: permissionsIds,
      divisionId: divisionId,
    };

    try {
      await editClientAdminUserTrigger({ body: requestBody });
      toastNotify(t("adminEdit.successToast", { user: userData.email }), "success");
      navigateHook("/usermanagement/clientadmins");
    } catch (error: any) {
      setErrorMessage(getServerErrorMessage(error, t("adminEdit.requestErrorMessage")));
    }
  };

  useEffect(() => {
    if (permissionsData?.permissions && divisionsData?.divisions && (getValues("permissions").length === 0 || getValues("divisions").length === 0)) {
      reset({
        permissions: permissionsData!.permissions.map((perm) => ({
          id: perm.id,
          key: perm.key,
          displayText: t<string>(`adminUser.permissions.${perm.key}`),
          selected: userData.permissions.includes(perm.key),
        })),
        divisions: divisionsData?.divisions,
      });
    }
  }, [reset, permissionsData, getValues, t, userData.permissions, divisionsData]);

  return (
    <div>
      <PageHeader title={t("adminEdit.client.title")} subtitle={t("adminEdit.client.subtitle")} />
      <Form
        className="mt-4"
        onSubmit={onConfirmSubmit}
        onCancel={() => navigateHook("/usermanagement/clientadmins")}
        validationTrigger={trigger}
        submitButtonText={t("adminEdit.saveButton")}
      >
        <div className="content-center my-4">
          <div className="flex flex-row justify-between mb-4">
            <div className="w-1/2">
              <Input id="emailAddress" aria-label={t("adminEdit.ariaEmail")} type="text" readOnly={true} value={userData.email} />
            </div>
            <div className="w-1/2 ml-4"></div>
          </div>
        </div>

        <Controller
          control={control}
          name="divisions"
          render={() => {
            return getValues("divisions").length === 0 ? (
              <></>
            ) : (
              <ClientDivisionSelector
                className="mt-2"
                onSelection={onClientDivisionChange}
                clientReadOnly={true}
                initialClient={{
                  id: userData?.client?.id,
                  name: userData?.client?.name,
                  divisions: getValues("divisions"),
                }}
                initialDivisionId={userData?.division?.id}
                divisionPlaceholder={t("clientAdminManagement.divisionSelector.placeholder")}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="permissions"
          rules={{ validate: () => getValues("permissions").some((p) => p.selected) }}
          render={({ field: { value, onChange } }) => {
            return getValues("permissions").length === 0 ? (
              <></>
            ) : (
              <CheckboxGrid
                items={value}
                dependencies={clientAdminPermissionDependencies}
                headingText={t("adminEdit.permissions.selectPermissions")}
                onChange={(state) => onChange(state)}
              />
            );
          }}
        />

        {errors.permissions && <ErrorAlert content={t("adminEdit.noPermissionsSelectedError")} />}
        {permissionError && <ErrorAlert content={t("adminEdit.permissions.error")} />}
        {divisionError && <ErrorAlert content={t("adminEdit.divisionsError")} />}
      </Form>

      {showPermissionsModal && (
        <ConfirmationModal
          title={t("adminEdit.permissions.modalTitle")}
          message={t("adminEdit.permissions.modalMessage", { userEmail: userData.email })}
          cancelButtonText={t("adminEdit.permissions.modalDismissTitle")}
          confirmButtonText={t("adminEdit.permissions.modalConfirmTitle")}
          onCancel={() => {
            setShowPermissionsModal(false);
          }}
          onConfirm={onSubmit}
        />
      )}

      <ErrorAlert content={errorMessage} />
    </div>
  );
};
