import StylableButton from "shared/UI/Buttons/StylableButton";

type FormSubmitButtonProps = {
  text: string;
  onClick: VoidFunction;
  disabled: boolean;
};

export const FormSubmitButton = (props: FormSubmitButtonProps) => {
  return (
    <StylableButton
      className="form-button form-button-submit"
      aria-label={props.text}
      fullWidth
      color="primary"
      type="button"
      text={props.text}
      onClick={props.onClick}
      disabled={props.disabled}
    />
  );
};

FormSubmitButton.defaultProps = { disabled: false };
