import StylableButton from "shared/UI/Buttons/StylableButton";
import { useNavigate } from "react-router-dom";
import { PageHeader } from "../../../UIPalette/components/PageHeader/PageHeader";
import {
  RowMenuItem,
  Table,
  TableHeader,
  TableOrdering,
  TablePaging
} from "../../../UIPalette/components/Table/Table";
import { t } from "i18next";
import Input from "@brighthr/component-input";
import {
  TargetGroupResponseModel,
  useDeleteTargetGroupFunctionRequest,
  useListTargetGroupsFunctionRequest,
} from "shared/request/myHealthyAdvantageApi";
import ErrorAlert from "shared/UI/Alerts/ErrorAlert";
import ViewLoading from "shared/UI/Spinners/ViewLoading";
import { OrderByDirection, RoleKeys } from "core/Constants";
import { ListTargetGroupsOrderBy } from "./ListTargetGroupsOrderBy";
import { useState } from "react";
import { useDebounce } from "shared/core/hooks/useDebounce";
import { useAdminUserContext } from "core/state/AdminUserContext";
import { toastNotify } from "shared/UI/Toaster/Toaster";
import { ConfirmationModal } from "shared/UI/Modal/ConfirmationModal/ConfirmationModal";

const createTargetGroupUri = "/targetgroups/create";
const editTargetGroupUri = "/targetgroups/edit";

export const ViewTargetGroups = () => {
  const navigate = useNavigate();
  const { trigger: deleteTargetGroupRequest } = useDeleteTargetGroupFunctionRequest();
  const [searchNameFilter, setSearchNameFilter] = useState<string>();
  const [paging, setPaging] = useState<TablePaging>({ current: 1, size: 10 });
  const [ordering, setOrdering] = useState<TableOrdering>({
    column: ListTargetGroupsOrderBy.CreatedDateUtc,
    direction: OrderByDirection.Descending
  });
  const [targetGroupToDelete, setTargetGroupToDelete] = useState<TargetGroupResponseModel>();
  const debouncedSearchNameFilter = useDebounce(searchNameFilter, 300);
  const { isAuthorised } = useAdminUserContext();

  const {
    data: targetGroupsResponse,
    error,
    isLoading,
    mutate,
  } = useListTargetGroupsFunctionRequest({
    nameFilter: debouncedSearchNameFilter,
    page: paging.current,
    perPage: paging.size,
    orderBy: ordering.column,
    orderByDirection: ordering.direction,
  });

  const tableHeaders: TableHeader[] = [
    { text: t("targetGroups.view.table.nameHeading"), orderByValue: ListTargetGroupsOrderBy.Name },
    {
      text: t("targetGroups.view.table.creationDateHeading"),
      orderByValue: ListTargetGroupsOrderBy.CreatedDateUtc,
      defaultOrderDirection: OrderByDirection.Descending,
    },
  ];

  const buildRowMenuItems = () => {
    let rowMenuItems: RowMenuItem[] = [];

    if (isAuthorised({ role: RoleKeys.HaAdmin, permissions: ["view-clients"] })) {
      rowMenuItems.push({
        title: t("table.editTitle"),
        ariaLabel: t("table.ariaEditRow"),
        onClick: (row: number) => onEditAdminUser(row),
      });

      rowMenuItems.push({
        title: t("table.deleteTitle"),
        ariaLabel: t("table.ariaDeleteRow"),
        onClick: (row: number) => setTargetGroupToDelete(getTargetGroupFromRow(row)),
      });
    }

    return rowMenuItems;
  };

  const getTargetGroupFromRow = (row: number) => targetGroupsResponse?.records?.at(row);

  const onEditAdminUser = async (rowIndex: number) => {
    navigate(editTargetGroupUri, { state: targetGroupsResponse!.records![rowIndex] });
  };

  const mapTableData = () =>
      targetGroupsResponse?.records?.map(({ name, createdDate }) => {
        return {
          name,
          createdDate: String.formatDateTime(createdDate),
        };
      });

  const deleteTargetGroup = async () => {
    try {
      await deleteTargetGroupRequest({ query: { id: targetGroupToDelete!.id } });
      await mutate();
      toastNotify(t("targetGroups.delete.toastMessage"), "success");
    } catch {
      toastNotify(t("targetGroups.delete.requestErrorMessage"), "error");
    } finally {
      setTargetGroupToDelete(undefined);
    }
  };

  return (
      <>
        <PageHeader
            title={t("targetGroups.view.heading")}
            subtitle={t("targetGroups.view.subHeading")}
        />
        <Input
            aria-label={t("targetGroups.view.searchAriaLabel")}
            placeholder={t("targetGroups.view.searchPlaceholder")}
            onChange={(e) => setSearchNameFilter(e.target.value)}
        />
        {isLoading ? (
            <ViewLoading label={t("targetGroups.view.loading")} />
        ) : (
            <Table
                headers={tableHeaders}
                dataRows={mapTableData() ?? []}
                orderedByColumn={ordering.column}
                orderedDirection={ordering.direction}
                pageSize={paging.size}
                currentPage={paging.current}
                totalDataCount={targetGroupsResponse?.metadata?.totalCount ?? 0}
                onPagingChange={setPaging}
                onOrderingChange={setOrdering}
                actionButtons={
                  <StylableButton
                      className="rounded-button"
                      color="primary"
                      text={"+"}
                      onClick={() => navigate(createTargetGroupUri)}
                  />
                }
                rowMenuItems={buildRowMenuItems()}
            />
        )}
        {targetGroupToDelete && (
            <ConfirmationModal
                title={t("targetGroups.delete.modalTitle")}
                message={t("targetGroups.delete.modalMessage", { name: targetGroupToDelete!.name })}
                cancelButtonText={t("targetGroups.delete.modalDismissTitle")}
                confirmButtonText={t("targetGroups.delete.modalConfirmTitle")}
                onCancel={() => setTargetGroupToDelete(undefined)}
                onConfirm={deleteTargetGroup}
            />
        )}
        {error && <ErrorAlert content={t("targetGroups.view.requestErrorMessage")} />}
      </>
  );
};
