import Input from "@brighthr/component-input";
import { useTranslation } from "react-i18next";
import {
  CreateHaAdminUserCommand,
  useCreateHaAdminUserFunctionRequest,
  useListAdminPermissionsFunctionRequest,
} from "shared/request/myHealthyAdvantageApi";
import { Roles } from "../../../core/Constants";
import { PageHeader } from "../../../UIPalette/components/PageHeader/PageHeader";
import ErrorAlert from "shared/UI/Alerts/ErrorAlert";
import { emailRegex } from "shared/utils/regex";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Form } from "../../../UIPalette/components/Form/Form";
import { getServerErrorMessage } from "shared/utils/serverError";
import { CheckboxGrid, CheckboxGridItem } from "../../../UIPalette/components/CheckboxGrid/CheckboxGrid";
import { haAdminPermissionDependencies } from "../../adminUserPermissions";
import { toastNotify } from "shared/UI/Toaster/Toaster";

type Inputs = {
  emailAddress: string;
  permissions: CheckboxGridItem[];
};

export const CreateHAAdmin = () => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const { data, error: permissionError } = useListAdminPermissionsFunctionRequest({ role: Roles.HaAdmin });

  const { trigger: createHaAdminUserTrigger } = useCreateHaAdminUserFunctionRequest();
  const navigateHook = useNavigate();
  const {
    control,
    formState: { errors },
    getValues,
    reset,
    trigger,
  } = useForm<Inputs>({
    defaultValues: {
      emailAddress: "",
      permissions: [],
    },
  });

  const onSubmit = async () => {
    setErrorMessage("");

    const selectedPermissions = getValues("permissions").filter((p) => p.selected);
    const permissionsIds = selectedPermissions.map(function (v) {
      return v.id;
    });

    const fullData: CreateHaAdminUserCommand = {
      emailAddress: getValues("emailAddress"),
      permissions: permissionsIds,
    };

    try {
      await createHaAdminUserTrigger({ body: fullData });
      toastNotify(t("adminCreate.successToast", { user: getValues("emailAddress") }), "success");
      navigateHook("/usermanagement/haadmins");
    } catch (error: any) {
      setErrorMessage(getServerErrorMessage(error, t("adminCreate.requestErrorMessage")));
    }
  };

  const cancelCreate = async () => {
    navigateHook("/usermanagement/haadmins");
  };

  useEffect(() => {
    if (data?.permissions && getValues("permissions").length === 0) {
      reset({
        permissions: data!.permissions.map((perm) => ({
          id: perm.id,
          key: perm.key,
          displayText: t<string>(`adminUser.permissions.${perm.key}`),
          selected: true,
        })),
      });
    }
  }, [reset, data, getValues, t]);

  return (
    <div>
      <PageHeader title={t("adminCreate.ha.title")} subtitle={t("adminCreate.ha.subtitle")} />
      <Form className="mt-4" onSubmit={onSubmit} onCancel={cancelCreate} validationTrigger={trigger}>
        <Controller
          name={"emailAddress"}
          control={control}
          rules={{ required: true, pattern: emailRegex }}
          render={({ field: { onChange, value } }) => (
            <div className="w-1/2">
              <Input
                id="emailAddress"
                aria-label={t("adminCreate.ariaEmail")}
                placeholder={t("adminCreate.emailBoxPlaceholder")}
                type="text"
                onChange={onChange}
                value={value}
              />
              {errors.emailAddress?.type === "pattern" && <ErrorAlert content={t("adminCreate.emailInvalidError")} />}
              {errors.emailAddress?.type === "required" && <ErrorAlert content={t("adminCreate.emailRequiredError")} />}
            </div>
          )}
        />

        <Controller
          control={control}
          name="permissions"
          rules={{ validate: () => getValues("permissions").some((p) => p.selected) }}
          render={({ field: { value, onChange } }) => {
            return getValues("permissions").length === 0 ? (
              <></>
            ) : (
              <CheckboxGrid
                items={value}
                dependencies={haAdminPermissionDependencies}
                headingText={t("adminCreate.permissions.selectPermissions")}
                onChange={(state) => onChange(state)}
              />
            );
          }}
        />
        {errors.permissions && <ErrorAlert content={t("adminCreate.noPermissionsSelectedError")} />}
        {permissionError && <ErrorAlert content={t("adminCreate.permissions.error")} />}
      </Form>
      <ErrorAlert content={errorMessage} />
    </div>
  );
};
