import { ChartOptions } from "chart.js";

export const ChartColors = ["#61BFCE", "#6054B8", "#C24300", "#84DFC1", "#009262", "#BEB4FF"];

export const GroupSpecificKeyedColours: Record<string, string> = {
  All: "#61BFCE",
  Male: "#61BFCE",
  Female: "#6054B8",
  NonBinary: "#C24300",
  PreferNotToSay: "#84DFC1",
  Age16To19: "#61BFCE",
  Age20To29: "#6054B8",
  Age30To39: "#C24300",
  Age40To49: "#84DFC1",
  Age50To59: "#BEB4FF",
  Age60Plus: "#009262",
};

export const doughnutChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "right",
    },
  },
} as ChartOptions<"doughnut">;

export const lineChartOptions = {
  responsive: true,
  maintainAspectRatio: true,
  aspectRatio: 3.2,
  scales: {
    y: {
      min: 0,
    },
  },
} as ChartOptions<"line">;

export const pieChartOptions = {
  responsive: true,
  maintainAspectRatio: true,
  aspectRatio: 3.2,
} as ChartOptions<"pie">;

export const barChartOptions = {
  responsive: true,
  maintainAspectRatio: true,
  aspectRatio: 3.2,
  scales: {
    y: {
      ticks: {
        beginAtZero: true,
        callback: function (value: number) {
          if (value % 1 === 0) {
            return value;
          }
        },
      },
    },
    x: {
      stacked: true,
    },
  },
} as ChartOptions<"bar">;

export const moodLineChartOptions = {
  ...lineChartOptions,
  scales: {
    y: {
      min: 0,
      max: 5.5,
      ticks: {
        stepSize: 1,
        callback: (label) => "",
      },
      title: {
        display: true,
        padding: 8,
      },
    },
  },
} as ChartOptions<"line">;

export enum XAxisSpan {
  weeks = 7,
  days = 1,
}