import { DataDimension, DataPoint } from "shared/request/myHealthyAdvantageApi";

export const formatDimension = (dimension: DataDimension): string =>
  formatDataPoint({ key: dimension.key, value: pipe(mapValues, sumValues)(dimension) });
export const formatDimensionWithAverage = (dimension: DataDimension): string =>
  labelWith(splitPascalCase(dimension.key!)!, pipe(mapValues, avgValues)(dimension), "Avg");
export const formatDimensionWithCustomLabel = (dimension: DataDimension, label: string): string =>
  labelWithTotal(label, pipe(mapValues, sumValues)(dimension));
export const formatDataPoint = (dataPoint: DataPoint): string => labelWithTotal(splitPascalCase(dataPoint.key!)!, dataPoint.value!);
export const formatDataPointWithAggregateTotal = (dataPoint: DataPoint, values: number[]): string =>
  labelWithTotal(splitPascalCase(dataPoint.key!)!, sumValues(values)!);

const splitPascalCase = (label: string) => label.match(/[A-Z]+(?![a-z])|[A-Z]?[a-z]+|\d+/g)?.join(" ");
const labelWithTotal = (label: string, total: number) => labelWith(label, total, "Total");
const labelWith = (label: string, value: number, valueLabel: string) => `${label} (${valueLabel}: ${value})`;
const sumValues = (values: number[]): number | undefined => values.reduce((total, value) => total + value, 0);
const avgValues = (values: number[]): number | undefined => {
  const sum = sumValues(values) ?? 0;
  return Number((sum / values.length).toFixed(2));
};
const mapValues = (dimension: DataDimension): number[] => dimension.value!.map((x) => x!.value!);
const _pipe = (a: Function, b: Function) => (arg: any) => b(a(arg));
const pipe = (...ops: Function[]) => ops.reduce(_pipe);
