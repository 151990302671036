import Select from "@brighthr/component-select";
import { ChangeEventHandler } from "react";
import { useListClientDivisionsFunctionRequest } from "shared/request/myHealthyAdvantageApi";

type DivisionSelectProps = {
  clientId: string;
  onChange: ChangeEventHandler;
  value: string;
  ariaLabel: string;
  placeholder: string;
};

export const DivisionSelect = ({ clientId, onChange, value, ariaLabel, placeholder }: DivisionSelectProps) => {
  const { data: divisionsData } = useListClientDivisionsFunctionRequest({ clientId });

  return (
    <div className="w-1/2">
      <Select onChange={onChange} aria-label={ariaLabel} className="bg-l-blue border-r-4 border-none text-base lg:text-lg" value={value ?? ""}>
        <option value={""}>{placeholder}</option>
        {divisionsData?.divisions &&
          divisionsData.divisions.map((division) => (
            <option key={division.id} value={division.id}>
              {division.name}
            </option>
          ))}
      </Select>
    </div>
  );
};
