import "shared/extensions/date";

import { ChartFilters } from "../../../../UIPalette/components/Charts/ChartProps";

export const filtersReducer = (
  state: ChartFilters,
  action: {
    type: string;
    payload: any;
  }
): ChartFilters => {
  switch (action.type) {
    case "SET_FROM_DATE":
      if (action.payload) {
        return { ...state, dateFrom: action.payload?.toDateOnly() };
      }
      return state;

    case "SET_TO_DATE":
      if (action.payload) {
        return { ...state, dateTo: action.payload?.toDateOnly() };
      }
      return state;

    case "SET_GROUPING":
      if (action.payload) {
        return { ...state, groupBy: action.payload.toString() };
      }
      return state;

    case "SET_CLIENT_AND_DIVISION":
      return {
        ...state,
        clientId: action.payload.clientId,
        divisionId: action.payload.divisionId,
      };

    case "SET_TARGET_GROUP":
      if (!action.payload) return state;
      const targetGroupClientIds = action.payload.clients.map((x: { id: string }) => x.id);
      return { ...state, targetGroupClientIds };

    case "SET_DIVISION":
      if (action.payload === "") {
        return { ...state, divisionId: undefined };
      }
      return { ...state, divisionId: action.payload };

    case "SET_LOCALE":
      if (action.payload === "") {
        return { ...state, localeId: undefined };
      }
      return { ...state, localeId: action.payload };

    default:
      return state;
  }
};
