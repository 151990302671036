import { Route, Routes } from "react-router-dom";
import { ViewWisdomUsers } from "./View/ViewWisdomUsers";

export const WisdomUserManagement = () => {
  return (
    <Routes>
      <Route index element={<ViewWisdomUsers />} />
    </Routes>
  );
};
