import { useState } from "react";

import { DivisionResponseModel } from "shared/request/myHealthyAdvantageApi";
import ErrorAlert from "shared/UI/Alerts/ErrorAlert";
import Input from "@brighthr/component-input";
import { LocaleSelect } from "shared/components/LocaleSelect";
import StylableButton from "shared/UI/Buttons/StylableButton";
import { ReactComponent as TrashButton } from "shared/assets/Icons/trash-button.svg";
import { ValueCallback } from "shared/core/types/callbacks";
import { useTranslation } from "react-i18next";

type ClientDivisionsEditorProps = {
  defaultLocale: string;
  initialDivisions: DivisionResponseModel[];
  onChange: ValueCallback<DivisionResponseModel[]>;
};

const ClientDivisionsEditor = ({ onChange, initialDivisions, defaultLocale }: ClientDivisionsEditorProps) => {
  const { t } = useTranslation();
  const [newDivision, setNewDivision] = useState("");
  const [divisionAlreadyExistsError, setDivisionAlreadyExistsError] = useState<string | undefined>();
  const [divisions, setDivisions] = useState(initialDivisions);

  const addDivision = () => {
    if (divisions.some((division) => division.name === newDivision)) {
      setDivisionAlreadyExistsError(newDivision);
      return;
    }

    const newDivisionInput = {
      name: newDivision,
      localeId: defaultLocale,
    };

    setDivisionAlreadyExistsError(undefined);
    const newDivisions = [newDivisionInput, ...divisions];
    setDivisions(newDivisions);
    setNewDivision("");
    onChange(newDivisions);
  };

  const removeDivision = (indexToDelete: number) => {
    const newDivisions = divisions.filter((division, index) => index !== indexToDelete);
    setDivisions(newDivisions);
    onChange(newDivisions);
  };

  const editDivisionName = (divisionToEditIndex: number, newName: string) => {
    const divisionsWithNewName = divisions.filter((division) => division.name === newName).length;
    setDivisionAlreadyExistsError(divisionsWithNewName > 0 ? newName : undefined);

    const newDivisions = divisions.map((division, index) => {
      if (index === divisionToEditIndex) {
        return { ...division, name: newName };
      }
      return division;
    });

    setDivisions(newDivisions);
    onChange(newDivisions);
  };

  const editDivisionLocale = (divisionToEditIndex: number, newLocale: string) => {
    const newDivisions = divisions.map((division, index) => {
      if (index === divisionToEditIndex) {
        return { ...division, localeId: newLocale };
      }
      return division;
    });

    setDivisions(newDivisions);
    onChange(newDivisions);
  };

  return (
    <>
      <h4 className="mt-8">{t("divisionEditor.heading")}</h4>
      <div className="flex flex-row mt-4">
        <div className="w-1/2">
          <Input
            aria-label={t("divisionEditor.ariaEnter")}
            placeholder={t("divisionEditor.placeholder")}
            onChange={(e) => {
              setNewDivision(e.target.value);
            }}
            value={newDivision}
          />
          {divisionAlreadyExistsError && <ErrorAlert content={t("divisionEditor.errorMessage", { name: divisionAlreadyExistsError })} />}
        </div>
        <div className="mt-1 ml-2">
          <StylableButton
            className="rounded-button-sm"
            color="primary"
            disabled={newDivision.trim().length === 0}
            text="+"
            aria-label={t("divisionEditor.ariaAdd")}
            onClick={addDivision}
          />
        </div>
      </div>
      {divisions?.length ? (
        divisions.map((division, index) => (
          <div className="flex flex-row gap-4 mt-4" key={index}>
            <div className="w-1/2">
              <Input
                key={division.id}
                value={division.name}
                onChange={(e) => {
                  editDivisionName(index, e.target.value);
                }}
              />
            </div>
            <LocaleSelect
              value={division.localeId}
              width="w-[200px]"
              onChange={(e) => editDivisionLocale(index, e.target.value)}
              ariaLabel={t("clientForm.ariaLocaleSelect")}
            />
            <div className="mt-1 ml-2">
              <TrashButton
                role="button"
                className="icon-button-sm"
                aria-label={t("divisionEditor.ariaDelete")}
                onClick={() => removeDivision(index)}
              />
            </div>
          </div>
        ))
      ) : (
        <></>
      )}
    </>
  );
};

export default ClientDivisionsEditor;
