import Input from "@brighthr/component-input";
import { useState } from "react";
import { Hideable } from "shared/UI/Hideable/Hideable";
import useComponentVisible from "shared/UI/Hooks/useComponentVisible";
import "./Typeahead.css";

export type Suggestion<TValue> = {
  value: TValue | undefined;
  displayText: string | undefined;
};

type TypeaheadProps<TValue> = {
  placeholder: string;
  suggestions: Suggestion<TValue>[];
  onTextChanged: (value: string) => void;
  onItemSelected: (item?: Suggestion<TValue>) => void;
  readOnly?: boolean;
  initialText?: string;
  autoFill?: boolean;
};

export const Typeahead = <TValue extends any>({
  placeholder,
  suggestions,
  onTextChanged,
  onItemSelected,
  initialText,
  readOnly,
  autoFill = true,
  ...rest
}: TypeaheadProps<TValue>) => {
  const [inputValue, setInputValue] = useState(initialText ?? "");
  const { targetAreaRef, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  function onTextChangedWrapper(value: string) {
    setInputValue(value);
    showHideSuggestions(value);
    onTextChanged(value);

    if (value.trim().length === 0) {
      onItemSelected(undefined);
    }
  }

  function onItemSelectedWrapper(selectedValue: Suggestion<TValue>) {
    if (autoFill) {
      setInputValue(selectedValue.displayText ?? "");
    }
    onItemSelected(selectedValue);
    setIsComponentVisible(false);
  }

  function showHideSuggestions(value: string) {
    setIsComponentVisible(value.trim().length > 0 && readOnly !== true);
  }

  return (
    <div ref={targetAreaRef} className="relative w-full">
      <Input
        placeholder={placeholder}
        onFocus={(e) => showHideSuggestions(e.target.value)}
        onChange={(e) => onTextChangedWrapper(e.target.value)}
        value={inputValue}
        readOnly={readOnly}
        {...rest}
      />
      <Hideable hidden={!isComponentVisible}>
        <div className="suggestions w-full">
          {suggestions.map((s, i) => (
            <div key={i} className="suggestion">
              <button className="w-full text-left" aria-label={`suggestion: ${s.displayText}`} onClick={() => onItemSelectedWrapper(s)}>
                {s.displayText}
              </button>
            </div>
          ))}
        </div>
      </Hideable>
    </div>
  );
};
