import React, { ComponentType, PropsWithChildren, useState } from "react";
import { ReactComponent as DownButton } from "shared/assets/Icons/down.svg";
import { Hideable } from "shared/UI/Hideable/Hideable";
import { AuthorisationProperties, useAdminUserContext } from "core/state/AdminUserContext";

type NavbarDropDownProps = {
  title: string;
  Icon: ComponentType<React.SVGProps<SVGSVGElement>>;
  isExpanded: boolean;
  forAnyOf?: AuthorisationProperties[][];
};
export const NavbarDropDown = ({ title, Icon, isExpanded, children, forAnyOf, ...rest }: NavbarDropDownProps & PropsWithChildren) => {
  const [isOpen, setIsOpen] = useState(false);

  const { isAuthorised } = useAdminUserContext();

  let hasAuth = true;
  if (forAnyOf !== undefined) {
    hasAuth = forAnyOf.some((authProp) => authProp.some((auth) => isAuthorised(auth)));
  }
  if (!hasAuth) {
    return null;
  }

  return (
    <>
      <button
        className={`flex flex-row p-2 navlink w-full items-center ${isExpanded ? "justify-start" : "justify-center"}`}
        onClick={() => setIsOpen(!isOpen)}
        {...rest}
      >
        <Icon alt-text={title} className={`${isExpanded ? "w-1/6" : ""}`} />
        <Hideable hidden={!isExpanded}>
          <span className="ml-3 w-4/6 select-none text-left">{title}</span>
          <div className="w-1/6 flex justify-end">
            <DownButton className={`justify-end ${isOpen ? "transform -scale-y-100" : ""}`} />
          </div>
        </Hideable>
      </button>
      <Hideable hidden={!isOpen}>{children}</Hideable>
    </>
  );
};
