import { CategoryScale, Chart as ChartJS, ChartOptions, Legend, LinearScale, LineElement, Plugin, PointElement, Title, Tooltip } from "chart.js";
import { Line } from "react-chartjs-2";
import { DataDimension, DateOnly, ReportResponseModel } from "shared/request/myHealthyAdvantageApi";
import ViewLoading from "shared/UI/Spinners/ViewLoading";
import { ChartColors, lineChartOptions, XAxisSpan } from "./ChartConstants";
import ErrorAlert from "shared/UI/Alerts/ErrorAlert";
import { ChartProps } from "./ChartProps";
import { useGetReport } from "./Hooks/useGetReport";
import { formatDimension } from "./dimensionLabelFormatter";
import { useCallback, useEffect } from "react";
import { TimeSeriesLineChartExporter } from "./Export/export";
import { t } from "i18next";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);



export type LineChartProps = {
  options?: ChartOptions<"line">;
  dimensionLabelFormatter?: (dimension: DataDimension) => string;
  plugins?: Plugin<"line">[];
  xAxisSpan: XAxisSpan;
} & ChartProps;

export const LineChart = (chartProps: LineChartProps) => {
  const { data, isLoading, error } = useGetReport(chartProps);
  const exportData = useCallback(
    () => chartProps.dataExporter.export(data, chartProps.filters.dateFrom, chartProps.filters.dateTo),
    [data, chartProps]
  );

  useEffect(() => {
    document.addEventListener("exportReport", exportData);
    return () => {
      document.removeEventListener("exportReport", exportData);
    };
  }, [exportData]);

  if (chartProps.translatedLabels) {
    throw new Error("Translated labels are not supported by line charts");
  }

  if (error) {
    return <ErrorAlert title={t("charts.requestErrorTitle")} content={t("charts.requestErrorBody")} />;
  }

  if (isLoading) {
    return <ViewLoading />;
  }

  if (data?.dataDimensions === undefined || data.dataDimensions.length <= 0) {
    return <p>{t("charts.requestNoData")}</p>;
  }

  const xAxisDates = getDates(chartProps.filters.dateFrom, chartProps.filters.dateTo, chartProps.xAxisSpan);
  const dataset = mapDataset(data, xAxisDates, chartProps.colours, chartProps.dimensionLabelFormatter ?? formatDimension);

  return (
    <Line
      data={{
        labels: xAxisDates,
        datasets: dataset,
      }}
      options={chartProps.options ?? lineChartOptions}
      width={300}
      plugins={chartProps.plugins}
    />
  );
};

function getDates(dateFrom: DateOnly, dateTo: DateOnly, xAxisSpan: XAxisSpan) {
  return Array.from(dateRange(Date.fromDateOnly(dateFrom)!, Date.fromDateOnly(dateTo)!, xAxisSpan));
}

function* dateRange(start: Date, end: Date, xAxisSpan: XAxisSpan): Generator<string> {
  const current = new Date(start);
  const last = new Date(end);

  while (current <= last) {
    yield new Date(current).toLocaleDateString("en-GB");
    current.setDate(current.getDate() + xAxisSpan);
  }
}

function mapDataset(
  data: ReportResponseModel,
  xAxisDates: string[],
  colours: Record<string, string> | undefined,
  dimensionLabelFormatter: (dimension: DataDimension) => string
) {
  return data.dataDimensions!.map((dimension, index) => {
    const colour = colours === undefined ? ChartColors[index] : colours[dimension.key!];

    return {
      label: dimensionLabelFormatter(dimension),
      data: padSeries(xAxisDates, dimension),
      borderColor: colour,
      backgroundColor: colour,
    };
  });
}

function padSeries(xAxisDates: string[], series: DataDimension) {
  return xAxisDates.map((x) => series.value!.find((element) => element.key === x)?.value || 0);
}

LineChart.defaultProps = {
  colours: undefined,
  dataExporter: new TimeSeriesLineChartExporter(),
  translatedLabels: false,
  xAxisSpan: XAxisSpan.weeks,
};
