import { Hideable } from "shared/UI/Hideable/Hideable";
import { NavbarItem, NavbarItemProps } from "./NavbarItem";
import { AuthorisationProperties, useAdminUserContext } from "core/state/AdminUserContext";

type ProtectedNavBarItemProps = {
  requiredAuthorisations: AuthorisationProperties[];
} & NavbarItemProps;

export const ProtectedNavbarItem = ({ requiredAuthorisations, ...rest }: ProtectedNavBarItemProps) => {
  const { isAuthorised } = useAdminUserContext();

  return (
    <Hideable hidden={requiredAuthorisations.every((requiredAuth) => !isAuthorised(requiredAuth))}>
      <NavbarItem {...rest} />
    </Hideable>
  );
};
