import React, { ComponentType } from "react";

import { Hideable } from "shared/UI/Hideable/Hideable";
import { NavLink } from "react-router-dom";

export type NavbarItemProps = {
  href: string;
  name: string;
  Icon: ComponentType<React.SVGProps<SVGSVGElement>> | null;
  isExpanded: boolean;
};

export const NavbarItem = ({ href, name, Icon, isExpanded }: NavbarItemProps) => {
  return (
    <li>
      <NavLink
        to={href}
        className={({ isActive }) =>
          `flex ${isExpanded ? "justify-start" : "justify-center"} p-2 rounded-lg ${isActive ? "active-navlink" : ""} navlink`
        }
      >
        {Icon == null ? <span className={`${isExpanded ? "w-1/6" : ""}`} /> : <Icon alt-text={name} className={`${isExpanded ? "w-1/6" : ""}`} />}
        <Hideable hidden={!isExpanded}>
          <span className="w-4/6 ml-3 select-none">{name}</span>
        </Hideable>
      </NavLink>
    </li>
  );
};
