import { Route, Routes } from "react-router-dom";
import { ViewHAAdmins } from "./View/ViewHAAdmins";
import { CreateHAAdmin } from "./Create/CreateHAAdmin";
import { EditHAAdmin } from "./Edit/EditHAAdmin";

export const HAAdminManagement = () => {
  return (
    <Routes>
      <Route index element={<ViewHAAdmins />} />
      <Route path="create" element={<CreateHAAdmin />} />
      <Route path="edit" element={<EditHAAdmin />} />
    </Routes>
  );
};
