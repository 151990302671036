import { ButtonProps } from "@brighthr/component-button";
import { headerColour, headerSizeTypes, Modal, ModalBody, ModalFooter, widthTypes } from "../Modal";
import { ReactNode } from "react";
import StylableButton from "../../Buttons/StylableButton";

export type ConfirmationModalProps = {
  title: string;
  cancelButtonText: string;
  confirmButtonText: string;
  onCancel: VoidFunction;
  onConfirm: VoidFunction;
  width?: widthTypes;
  confirmButtonDisabled?: boolean;
  cancelButtonDisabled?: boolean;
  buttonSize?: Extract<ButtonProps["size"], string>;
  fullWidthButtons?: boolean;
  headerSize?: headerSizeTypes;
  headerColour?: headerColour;
};

export type ConfirmationModalPropsWithChildren = {
  children: ReactNode;
  message?: never;
} & ConfirmationModalProps;

export type ConfirmationModalPropsWithMessage = {
  message: string;
  children?: never;
} & ConfirmationModalProps;

export const ConfirmationModal = (props: ConfirmationModalPropsWithChildren | ConfirmationModalPropsWithMessage) => {
  const {
    title,
    cancelButtonText,
    confirmButtonText,
    onCancel,
    onConfirm,
    width = "xs",
    confirmButtonDisabled = false,
    cancelButtonDisabled = false,
    buttonSize = "base",
    fullWidthButtons = false,
    headerSize = "base",
  } = props;

  let bodyToRender: ReactNode;

  if ("children" in props) {
    bodyToRender = props.children;
  } else {
    bodyToRender = (
      <div className="h-20">
        <p className="pb-4">{props.message}</p>
      </div>
    );
  }

  const fullWidthButtonFooterClassName = fullWidthButtons ? "gap-4" : "";
  const fullWidthButtonClassName = fullWidthButtons ? "[&>*]:w-full flex-grow" : "";

  return (
    <Modal allowOverflow={true} title={title} width={width} headerSize={headerSize} close={() => {}} hideDismiss={true} color="inverted">
      <ModalBody>{bodyToRender}</ModalBody>
      <ModalFooter className={fullWidthButtonFooterClassName}>
        <StylableButton
          className={fullWidthButtonClassName}
          text={cancelButtonText}
          disabled={cancelButtonDisabled}
          outlineButton
          color="primary"
          size={buttonSize}
          onClick={onCancel}
        />
        <StylableButton
          className={fullWidthButtonClassName}
          text={confirmButtonText}
          disabled={confirmButtonDisabled}
          color="primary"
          size={buttonSize}
          onClick={onConfirm}
        />
      </ModalFooter>
    </Modal>
  );
};
