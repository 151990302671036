import { Suggestion, Typeahead } from "../../../UIPalette/components/Typeahead/Typeahead";
import { ClientLookupModel, useLookupClientsFunctionRequest } from "shared/request/myHealthyAdvantageApi";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDebounce } from "shared/core/hooks/useDebounce";
import { ValueCallback } from "shared/core/types/callbacks";

type ClientTypeAheadProps = React.HTMLAttributes<HTMLDivElement> & {
  onClientSelected: ValueCallback<ClientLookupModel | undefined>;
  readOnly?: boolean;
  initialClient?: ClientLookupModel;
  autoFill?: boolean;
};

export const ClientTypeAhead = ({ onClientSelected, readOnly, initialClient, placeholder, autoFill = true, ...props }: ClientTypeAheadProps) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState<string>(initialClient?.name ?? "");
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const { data } = useLookupClientsFunctionRequest({ clientName: debouncedSearchTerm! }, { shouldFetch: readOnly !== true });

  const mapClientSuggestions = () => data?.clients?.map((e) => ({ value: e, displayText: e.name } as Suggestion<ClientLookupModel>)) ?? [];

  useEffect(() => {
    onClientSelected(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  return (
    <div {...props}>
      <Typeahead
        placeholder={placeholder ?? t("clientAdminManagement.clientFilter.placeholder")}
        suggestions={mapClientSuggestions()}
        onTextChanged={(e: string) => setSearchTerm(e)}
        onItemSelected={(suggestion) => onClientSelected(suggestion?.value!)}
        aria-label={t("clientAdminManagement.ariaClientSearch")}
        readOnly={readOnly}
        initialText={searchTerm}
        autoFill={autoFill}
      />
    </div>
  );
};
