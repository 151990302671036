import "./Table.css";

import { ContextMenu, ContextMenuListItem } from "@brighthr/component-contextmenu";
import { ReactElement, useState } from "react";

import { OrderByDirection } from "src/core/Constants";
import { OrderableTableColumnHeader } from "./OrderableTableColumnHeader";
import { PageSizeSelector } from "../Paginator/PageSizeSelector";
import { Paginator } from "../Paginator/Paginator";
import { t } from "i18next";

export type TableHeader = {
  text: string;
  orderByValue: number;
  defaultOrderDirection?: OrderByDirection;
};

export type RowMenuItem = {
  title: string;
  ariaLabel: string;
  onClick: Function;
};

export type TableOrdering = {
  direction: OrderByDirection;
  column: number;
};

export type TablePaging = {
  current: number;
  size: number;
};

export type TableProps = {
  headers: TableHeader[];
  dataRows: { [key: string]: string | undefined }[];
  orderedByColumn: number;
  orderedDirection: OrderByDirection;
  currentPage: number;
  pageSize: number;
  totalDataCount: number;
  onOrderingChange: (ordering: TableOrdering) => void;
  onPagingChange: (paging: TablePaging) => void;
  actionButtons?: ReactElement;
  rowMenuItems?: RowMenuItem[];
};

export const Table = (props: TableProps) => {
  if (props.dataRows.length !== 0 && props.headers.length !== Object.keys(props.dataRows[0]).length)
    throw new Error("Table data row cells aren't the expected length outlined by the column headers");

  const [selectedRowIndex, setSelectedRowIndex] = useState(0);

  const onClickRowMoreMenu = (rowIndex: number) => {
    setSelectedRowIndex(rowIndex);
  };

  const renderRowMenuButton = (rowIndex: number) => {
    if (props.rowMenuItems && props.rowMenuItems.length > 0)
      return (
        <td>
          <ContextMenu
            key={`context-menu-row-${rowIndex}`}
            menuPosition="left"
            contextName="actions"
            onOpen={() => onClickRowMoreMenu(rowIndex)}
            aria-label={t("table.ariaRowMenuButton", { rowIndex: rowIndex })}
          >
            {props.rowMenuItems?.map((menuItem, menuItemIndex) => {
              return (
                <ContextMenuListItem
                  className="[&_span]:text-[14pt] [&_span]:font-bold py-2 pl-2"
                  key={`context-menu-item-row${rowIndex}-index${menuItemIndex}`}
                  button
                  onClick={() => menuItem.onClick(selectedRowIndex)}
                  text={menuItem.title}
                  aria-label={menuItem.ariaLabel}
                />
              );
            })}
          </ContextMenu>
        </td>
      );
  };

  return (
    <div>
      <table className="my-2 ha-table">
        <thead>
          <tr>
            {props.headers.map((header, index) => {
              return (
                <OrderableTableColumnHeader
                  key={`header-${index}`}
                  onClick={props.onOrderingChange}
                  heading={header.text}
                  direction={props.orderedDirection}
                  column={header.orderByValue}
                  defaultDirection={header.defaultOrderDirection}
                  currentColumn={props.orderedByColumn}
                />
              );
            })}
            {props.rowMenuItems && props.rowMenuItems.length > 0 && <th />}
          </tr>
        </thead>
        <tbody>
          {props.dataRows.map((row, rowIndex) => {
            return (
              <tr key={`row-${rowIndex}`}>
                {Object.keys(row).map((cell, cellIndex) => {
                  return <td key={`cell-${rowIndex}-${cellIndex}`}>{row[cell]}</td>;
                })}
                {renderRowMenuButton(rowIndex)}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="flex flex-row items-center my-2">
        <PageSizeSelector
          value={props.pageSize}
          onChanged={(perPage: number) => {
            props.onPagingChange({ current: 1, size: perPage });
          }}
        />
        <Paginator
          page={props.currentPage}
          pageSize={props.pageSize}
          total={props.totalDataCount}
          onChanged={(pageNumber: number) => props.onPagingChange({ current: pageNumber, size: props.pageSize })}
        />
        <div className="flex-1" />
        {props.actionButtons}
      </div>
    </div>
  );
};
