import { useTranslation } from "react-i18next";
import { useCallback, useEffect } from "react";
import { ChartProps } from "./ChartProps";
import { UsersPerClientDataExporter } from "./Export/export";
import { useGetUsersPerClientFunctionRequest } from "shared/request/myHealthyAdvantageApi";

export const ExportOnly = (chartProps: ChartProps) => {
  const { t } = useTranslation();
  const { data } = useGetUsersPerClientFunctionRequest();
  const exportData = useCallback(
    () => chartProps.dataExporter.export(data, chartProps.filters.dateFrom, chartProps.filters.dateTo),
    [chartProps.dataExporter, chartProps.filters.dateFrom, chartProps.filters.dateTo, data]
  );

  useEffect(() => {
    document.addEventListener("exportReport", exportData);
    return () => {
      document.removeEventListener("exportReport", exportData);
    };
  }, [exportData]);

  return (
    <div>
      <h4>{t("dashboard.exportOnly")}</h4>
    </div>
  );
};

ExportOnly.defaultProps = {
  colours: undefined,
  dataExporter: new UsersPerClientDataExporter("UsersPerClient"),
  translatedLabels: false,
};
