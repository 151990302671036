import { ClientResponseModel, DivisionModel, EditClientCommand, useEditClientFunctionRequest } from "shared/request/myHealthyAdvantageApi";
import { useLocation, useNavigate } from "react-router-dom";

import { CheckboxGridItem } from "../../../../UIPalette/components/CheckboxGrid/CheckboxGrid";
import { ClientForm } from "../../Components/ClientForm/ClientForm";
import { getServerErrorMessage } from "shared/utils/serverError";
import { toastNotify } from "shared/UI/Toaster/Toaster";
import { useTranslation } from "react-i18next";

export type Inputs = {
  name: string;
  phoneNumber: string;
  code: string;
  schemeNumber: string;
  userLimit: number;
  localeId: string;
  modules: CheckboxGridItem[];
  divisions: DivisionModel[];
};

const viewClientsPath = "/clientmanagement";

export const EditClient = () => {
  const { t } = useTranslation();
  const navigateHook = useNavigate();
  const { state } = useLocation();
  let clientData: ClientResponseModel = state;
  const { trigger: editClientRequest } = useEditClientFunctionRequest({});

  const onSubmit = async (clientDetails: Inputs) => {
    const selectedModules = clientDetails.modules.filter((module) => module.selected).map((item) => item.id);
    const command: EditClientCommand = {
      ...clientDetails,
      id: clientData.id,
      userLimit: Number(clientDetails.userLimit),
      modules: selectedModules,
      localeId: clientDetails.localeId,
      divisions: clientDetails.divisions,
    };

    try {
      await editClientRequest({ body: command });
      toastNotify(t("clientForm.successToast", { clientName: clientDetails.name, action: "edited" }), "success");
      navigateHook(viewClientsPath);
    } catch (error: any) {
      toastNotify(getServerErrorMessage(error, t("clientForm.requestErrorMessage")), "error");
    }
  };

  return <ClientForm onSubmit={onSubmit} name="editClient" />;
};
