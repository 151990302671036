import { ChartProps } from "./ChartProps";
import { useTranslation } from "react-i18next";
import { useGetReport } from "./Hooks/useGetReport";
import ErrorAlert from "shared/UI/Alerts/ErrorAlert";
import ViewLoading from "shared/UI/Spinners/ViewLoading";
import { Bar } from "react-chartjs-2";
import { barChartOptions, ChartColors } from "./ChartConstants";
import { ReportResponseModel } from "shared/request/myHealthyAdvantageApi";
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from "chart.js";
import { formatDataPointWithAggregateTotal, formatDimension, formatDimensionWithCustomLabel } from "./dimensionLabelFormatter";
import { useCallback, useEffect } from "react";
import { BarChartExporter } from "./Export/export";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const BarChart = ({ reportType, filters, dataExporter, colours, translatedLabels }: ChartProps) => {
  const { t } = useTranslation();
  const { data, isLoading, error } = useGetReport({ reportType, filters });
  const exportData = useCallback(
    () => dataExporter.export(data, filters.dateFrom, filters.dateTo),
    [data, dataExporter, filters.dateFrom, filters.dateTo]
  );

  useEffect(() => {
    document.addEventListener("exportReport", exportData);
    return () => {
      document.removeEventListener("exportReport", exportData);
    };
  }, [exportData]);

  if (error) {
    return <ErrorAlert title={t("charts.requestErrorTitle")} content={t("charts.requestErrorBody")} />;
  }

  if (isLoading) {
    return <ViewLoading />;
  }

  if (data?.dataDimensions === undefined || data.dataDimensions.length <= 0) {
    return <p>{t("charts.requestNoData")}</p>;
  }

  const labels = data.dataDimensions.map((dimension) =>
    translatedLabels
      ? // @ts-ignore
        formatDimensionWithCustomLabel(dimension, t(`charts.labels.${dimension.key}`))
      : formatDimension(dimension)
  );
  const dataset = mapDataset(data, colours);

  return (
    <Bar
      data={{
        labels: labels,
        datasets: dataset,
      }}
      options={barChartOptions}
    />
  );
};

function mapDataset(data: ReportResponseModel, colours: Record<string, string> | undefined) {
  return data.dataDimensions![0].value!.map((firstDataPoint, index) => {
    const dataPoints = data.dataDimensions
      ?.map((x) => x.value)
      .flat(2)
      .filter((x) => x!.key === firstDataPoint.key)
      .map((x) => x!.value!);
    return {
      label: formatDataPointWithAggregateTotal(firstDataPoint, dataPoints!),
      data: dataPoints,
      backgroundColor: colours === undefined ? ChartColors[index] : colours[index],
      stack: firstDataPoint.key,
    };
  });
}

BarChart.defaultProps = {
  colours: undefined,
  dataExporter: new BarChartExporter(),
  translatedLabels: false,
};
