export enum ReportType {
  Logins = 0,
  Registrations = 1,
  NumberOfUsers = 2,
  SupportRequests = 3,
  MiniHealthChecks = 4,
  Trackers = 5,
  FourWeekPlans = 6,
  Preferences = 7,
  MoodTrackers = 8,
  TotalUsersByClient = 9,
  DailyActiveUsers = 10,
}

export enum GroupingValues {
  None = 0,
  AgeRange = 1,
  Gender = 2,
}

