import { ReactComponent as UnsortedIcon } from "shared/assets/Icons/sorting-unsorted.svg";
import { ReactComponent as SortedAscendingIcon } from "shared/assets/Icons/sorting-ascending.svg";
import { ReactComponent as SortedDescendingIcon } from "shared/assets/Icons/sorting-descending.svg";

import { OrderByDirection } from "../../../core/Constants";
import { t } from "i18next";

type OrderableTableColumnHeaderProps = {
  heading: string;
  currentColumn: number;
  column: number;
  direction: OrderByDirection;
  defaultDirection?: OrderByDirection | undefined;
  onClick: (ordering: { direction: OrderByDirection; column: number }) => void;
};

export const OrderableTableColumnHeader = ({
  heading,
  currentColumn,
  column,
  direction,
  onClick,
  defaultDirection = OrderByDirection.Ascending,
}: OrderableTableColumnHeaderProps) => {
  const handleClick = () => {
    let newDirection = defaultDirection;

    if (column === currentColumn) {
      newDirection = direction === OrderByDirection.Ascending ? OrderByDirection.Descending : OrderByDirection.Ascending;
    }

    onClick({ direction: newDirection, column });
  };

  const getIcon = () => {
    if (column !== currentColumn) {
      return <UnsortedIcon className="ml-2" title={t("table.UnorderedColumnIconTitle")} />;
    }
    if (direction === OrderByDirection.Ascending) {
      return <SortedAscendingIcon className="ml-2" title={t("table.OrderedAscendingColumnIconTitle")} />;
    }

    if (direction === OrderByDirection.Descending) {
      return <SortedDescendingIcon className="ml-2" title={t("table.OrderedDescendingColumnIconTitle")} />;
    }
  };

  return (
    <th onClick={handleClick}>
      <div className="flex">
        {heading} {getIcon()}
      </div>
    </th>
  );
};
