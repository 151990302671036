import { ClientLookupModel } from "shared/request/myHealthyAdvantageApi";
import { useState } from "react";
import Select from "@brighthr/component-select";
import { useTranslation } from "react-i18next";
import { ValueCallback } from "shared/core/types/callbacks";
import { ClientTypeAhead } from "../ClientTypeAhead/ClientTypeAhead";

export type ClientDivisionSelection = {
  clientId: string | undefined;
  divisionId: string | undefined;
};

type ClientDivisionSelectorProps = React.HTMLAttributes<HTMLDivElement> & {
  onSelection: ValueCallback<ClientDivisionSelection>;
  clientReadOnly?: boolean;
  initialClient?: ClientLookupModel;
  initialDivisionId?: string;
  divisionPlaceholder?: string;
};

export const ClientDivisionSelector = ({
  onSelection,
  clientReadOnly,
  initialClient,
  initialDivisionId,
  divisionPlaceholder,
}: ClientDivisionSelectorProps) => {
  const { t } = useTranslation();
  const [selectedClient, setSelectedClient] = useState<ClientLookupModel | undefined>(initialClient);
  const [selectedDivision, setSelectedDivision] = useState<string | undefined>(initialDivisionId);
  const divisions = selectedClient?.divisions ?? [];

  return (
    <div className="flex flex-row mb-4 justify-between">
      <ClientTypeAhead
        className="w-1/2"
        readOnly={clientReadOnly}
        onClientSelected={(client) => {
          if (clientReadOnly !== true) {
            setSelectedClient(client);
            setSelectedDivision(undefined);
            onSelection({ clientId: client?.id, divisionId: undefined });
          }
        }}
        initialClient={initialClient}
      />
      <div className="w-1/2 ml-4">
        <Select
          aria-label={t("clientAdminManagement.ariaDivisionFilter")}
          onChange={(e) => {
            const selectedDivision = e.target.value ? e.target.value : undefined;
            setSelectedDivision(selectedDivision);
            onSelection({ clientId: selectedClient?.id, divisionId: selectedDivision });
          }}
          className="bg-l-blue border-r-4 border-none text-base lg:text-lg"
          value={selectedDivision ?? ""}
        >
          <option value={""}>{divisionPlaceholder ?? t("clientAdminManagement.divisionFilter.placeholder")}</option>
          {divisions.map((division) => (
            <option key={division.id} value={division.id}>
              {division.name}
            </option>
          ))}
        </Select>
      </div>
    </div>
  );
};
