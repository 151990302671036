import { AdminUserResponseModel } from "shared/request/myHealthyAdvantageApi";

export abstract class AdminUserState {
  public static get(): AdminUserResponseModel | undefined {
    const adminUser = sessionStorage.getItem("adminUser");
    return adminUser ? JSON.parse(adminUser) : undefined;
  }

  public static set(adminUser: AdminUserResponseModel) {
    sessionStorage.setItem("adminUser", JSON.stringify(adminUser));
  }

  public static clear() {
    sessionStorage.removeItem("adminUser");
  }
}
