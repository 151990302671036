import { PropsWithChildren, ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "./useAuth";
import { useLocation } from "react-router-dom";
import { DestinationState } from "shared/core/state/destinationState";
import ViewLoading from "shared/UI/Spinners/ViewLoading";
import { AuthorisationProperties, useAdminUserContext } from "core/state/AdminUserContext";
import { SelectMenuOption } from "../../Views/SelectMenuOption/SelectMenuOption";

type ProtectedRouteProps = {
  requiredAuthorisations?: AuthorisationProperties[];
} & PropsWithChildren;

export const ProtectedRoute = ({ requiredAuthorisations, children }: ProtectedRouteProps): ReactElement => {
  const { t } = useTranslation();
  const auth = useAuth();
  const location = useLocation();
  const { isAuthorised } = useAdminUserContext();

  useEffect(() => {
    if (auth.shouldAuthenticate) {
      DestinationState.set(location.pathname);

      auth.signinRedirect();
    }
  }, [auth, location.pathname]);

  if (auth.activeNavigator) {
    return <ViewLoading label={t("login.inProgressMessage")} />;
  }

  if (!auth.isAuthenticated) {
    return <div>{t("login.error.title")}</div>;
  }

  if (requiredAuthorisations?.every((requiredAuth) => !isAuthorised(requiredAuth))) {
    return <SelectMenuOption />;
  }

  return <>{children}</>;
};

export function protect(el: ReactElement, requiredAuthorisations?: AuthorisationProperties[]): ReactElement {
  return <ProtectedRoute requiredAuthorisations={requiredAuthorisations}>{el}</ProtectedRoute>;
}
