import { Route, Routes } from "react-router-dom";
import { TargetGroup, TargetGroupPageType } from "./TargetGroup/TargetGroup";
import { ViewTargetGroups } from "./View/ViewTargetGroups";

export const TargetGroups = () => {
  return (
    <Routes>
      <Route index element={<ViewTargetGroups />} />
      <Route path="create" element={<TargetGroup pageType={TargetGroupPageType.Create} />} />
      <Route path="edit" element={<TargetGroup pageType={TargetGroupPageType.Edit} />} />
    </Routes>
  );
};
