import { Route, Routes } from "react-router-dom";
import { ViewClientAdmins } from "./View/ViewClientAdmins";
import { CreateClientAdmin } from "./Create/CreateClientAdmin";
import { EditClientAdmin } from "./Edit/EditClientAdmin";

export const ClientAdminManagement = () => {
  return (
    <Routes>
      <Route index element={<ViewClientAdmins />} />
      <Route path="create" element={<CreateClientAdmin />} />
      <Route path="edit" element={<EditClientAdmin />} />
    </Routes>
  );
};
