import { useMatchMedia } from "shared/UI/Hooks/useMatchMedia";
import { ResponsiveBreakpoints } from "shared/UI/ResponsiveBreakpoints";
import { DesktopNavBar } from "./Components/DesktopNavBar";
import { MobileNavBar } from "./Components/MobileNavBar";
import "./Navbar.css";

export const Navbar = () =>
  useMatchMedia(ResponsiveBreakpoints.Mobile, true) ? (
    <DesktopNavBar />
  ) : (
    <MobileNavBar />
  );
