import { useTranslation } from "react-i18next";
import {
  AdminUsersResponseModel,
  ListAdminUsersQuery,
  useDeleteHaAdminUserFunctionRequest,
  useListAdminUsersFunctionRequest,
} from "shared/request/myHealthyAdvantageApi";
import { useEffect, useState } from "react";
import { OrderByDirection, RoleKeys, Roles } from "core/Constants";
import ErrorAlert from "shared/UI/Alerts/ErrorAlert";
import Input from "@brighthr/component-input";
import { useDebounce } from "shared/core/hooks/useDebounce";
import { ListAdminUsersOrderBy } from "./ListAdminUsersOrderBy";
import { RowMenuItem, Table, TableHeader } from "../../../UIPalette/components/Table/Table";
import { PageHeader } from "../../../UIPalette/components/PageHeader/PageHeader";
import { useNavigate } from "react-router-dom";
import StylableButton from "shared/UI/Buttons/StylableButton";
import "shared/extensions/string";
import { useAdminUserContext } from "core/state/AdminUserContext";
import { toastNotify } from "shared/UI/Toaster/Toaster";
import { ConfirmationModal } from "shared/UI/Modal/ConfirmationModal/ConfirmationModal";

export const ViewHAAdmins = () => {
  const { t } = useTranslation();
  const { trigger: listAdminUsersRequest } = useListAdminUsersFunctionRequest();
  const { trigger: deleteAdminUserRequest } = useDeleteHaAdminUserFunctionRequest();
  const [userResponse, setUserResponse] = useState<AdminUsersResponseModel>();
  const [errorMessage, setErrorMessage] = useState("");
  const [searchFilter, setSearchFilter] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedAdminUserIndex, setSelectedAdminUserIndex] = useState(0);
  const [ordering, setOrdering] = useState({
    column: ListAdminUsersOrderBy.CreatedDateUtc,
    direction: OrderByDirection.Descending,
  });
  const [paging, setPaging] = useState({ current: 1, size: 10 });
  const debouncedSearchFilter = useDebounce(searchFilter, 500);
  const navigate = useNavigate();
  const { isAuthorised } = useAdminUserContext();

  const tableHeaders: TableHeader[] = [
    {
      text: t("haAdminManagement.table.nameHeading"),
      orderByValue: ListAdminUsersOrderBy.Forenames,
    },
    {
      text: t("haAdminManagement.table.surnameHeading"),
      orderByValue: ListAdminUsersOrderBy.Surname,
    },
    { text: t("haAdminManagement.table.emailHeading"), orderByValue: ListAdminUsersOrderBy.Email },
    {
      text: t("haAdminManagement.table.statusHeading"),
      orderByValue: ListAdminUsersOrderBy.IsActive,
    },
    {
      text: t("haAdminManagement.table.creationDateHeading"),
      orderByValue: ListAdminUsersOrderBy.CreatedDateUtc,
      defaultOrderDirection: OrderByDirection.Descending,
    },
  ];

  const buildRowMenuItems = () => {
    let rowMenuItems: RowMenuItem[] = [];

    if (isAuthorised({ role: RoleKeys.HaAdmin, permissions: ["delete-ha-admins"] })) {
      rowMenuItems.push({
        title: t("table.deleteTitle"),
        ariaLabel: t("table.ariaDeleteRow"),
        onClick: (rowIndex: number) => onDeleteAdminUser(rowIndex),
      });
    }

    if (isAuthorised({ role: RoleKeys.HaAdmin, permissions: ["edit-ha-admins"] })) {
      rowMenuItems.push({
        title: t("table.editTitle"),
        ariaLabel: t("table.ariaEditRow"),
        onClick: (rowIndex: number) => onEditAdminUser(rowIndex),
      });
    }

    return rowMenuItems;
  };

  const mapTableData = () =>
    userResponse?.records?.map(({ forenames, surname, email, isActive, ...rest }) => {
      return {
        forenames,
        surname,
        email,
        status: isActive ? t("haAdminManagement.status.active") : t("haAdminManagement.status.pending"),
        createdDate: String.formatDateTime(rest.createdDate),
      };
    });

  const deleteAdminUser = async () => {
    try {
      const userId = userResponse!.records![selectedAdminUserIndex].id;
      await deleteAdminUserRequest({ query: { id: userId } });
      setUserResponse({
        ...userResponse,
        records: userResponse?.records?.filter((record) => record.id !== userId),
      });

      toastNotify(t("adminDelete.toastMessage"), "success");
    } catch {
      setErrorMessage(t("adminDelete.ha.requestErrorMessage"));
    } finally {
      setShowDeleteModal(false);
    }
  };

  useEffect(() => {
    const requestBody: ListAdminUsersQuery = {
      roleId: Roles.HaAdmin,
      page: paging.current,
      perPage: paging.size,
      orderBy: ordering.column,
      orderByDirection: ordering.direction,
      filter: {
        keyword: debouncedSearchFilter,
      },
    };

    async function listAdminUsers() {
      try {
        setErrorMessage("");
        const response = await listAdminUsersRequest({ body: requestBody });
        setUserResponse(response?.data);
      } catch {
        setErrorMessage(t("haAdminManagement.requestErrorMessage"));
      }
    }

    listAdminUsers();
  }, [debouncedSearchFilter, listAdminUsersRequest, paging, ordering, t]);

  const onDeleteAdminUser = async (rowIndex: number) => {
    setSelectedAdminUserIndex(rowIndex);
    setShowDeleteModal(true);
  };

  const onEditAdminUser = async (rowIndex: number) => {
    navigate("/usermanagement/haadmins/edit", { state: userResponse!.records![rowIndex] });
  };

  return (
    <>
      <PageHeader title={t("haAdminManagement.title")} subtitle={t("haAdminManagement.subtitle")} />
      <div className="w-2/5 my-2 content-center">
        <Input
          aria-label={t("haAdminManagement.ariaSearch")}
          onChange={(a) => setSearchFilter(a.target.value)}
          rightIconName="search"
          placeholder={t("haAdminManagement.searchPlaceholder")}
        />
      </div>

      <Table
        headers={tableHeaders}
        dataRows={mapTableData() ?? []}
        orderedByColumn={ordering.column}
        orderedDirection={ordering.direction}
        currentPage={paging.current}
        pageSize={paging.size}
        totalDataCount={userResponse?.metadata?.totalCount ?? 0}
        onOrderingChange={setOrdering}
        onPagingChange={setPaging}
        actionButtons={
          <StylableButton className="rounded-button" color="primary" text={"+"} onClick={() => navigate("/usermanagement/haadmins/create")} />
        }
        rowMenuItems={buildRowMenuItems()}
      />
      {showDeleteModal && (
        <ConfirmationModal
          title={t("adminDelete.ha.modalTitle")}
          message={t("adminDelete.modalMessage", { userEmail: userResponse!.records![selectedAdminUserIndex].email })}
          cancelButtonText={t("adminDelete.modalDismissTitle")}
          confirmButtonText={t("adminDelete.modalConfirmTitle")}
          onCancel={() => {
            setShowDeleteModal(false);
          }}
          onConfirm={deleteAdminUser}
        />
      )}
      <ErrorAlert content={errorMessage} />
    </>
  );
};
