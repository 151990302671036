import Input from "@brighthr/component-input";
import { useTranslation } from "react-i18next";
import {
  EditHaAdminUserCommand,
  AdminUserResponseModel,
  useEditHaAdminUserFunctionRequest,
  useListAdminPermissionsFunctionRequest,
} from "shared/request/myHealthyAdvantageApi";
import { Roles } from "../../../core/Constants";
import { PageHeader } from "../../../UIPalette/components/PageHeader/PageHeader";
import ErrorAlert from "shared/UI/Alerts/ErrorAlert";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { Form } from "../../../UIPalette/components/Form/Form";
import { getServerErrorMessage } from "shared/utils/serverError";
import { CheckboxGrid, CheckboxGridItem } from "../../../UIPalette/components/CheckboxGrid/CheckboxGrid";
import { haAdminPermissionDependencies } from "../../adminUserPermissions";
import { toastNotify } from "shared/UI/Toaster/Toaster";
import { useAdminUserContext } from "core/state/AdminUserContext";
import { ReactComponent as InfoIcon } from "shared/assets/Icons/info.svg";

type Inputs = {
  permissions: CheckboxGridItem[];
};

export const EditHAAdmin = () => {
  const { user } = useAdminUserContext();
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const { data, error: permissionError } = useListAdminPermissionsFunctionRequest({ role: Roles.HaAdmin });

  const { trigger: editHaAdminUserTrigger } = useEditHaAdminUserFunctionRequest({});
  const navigateHook = useNavigate();
  const {
    control,
    formState: { errors },
    getValues,
    reset,
    trigger,
  } = useForm<Inputs>({
    defaultValues: {
      permissions: [],
    },
  });

  const userData = useLocation().state as AdminUserResponseModel;
  const isEditingSelf = userData.id === user?.id;

  const onSubmit = async () => {
    setErrorMessage("");

    const selectedPermissions = getValues("permissions").filter((p) => p.selected);
    const permissionsIds = selectedPermissions.map(function (v) {
      return v.id;
    });

    const requestBody: EditHaAdminUserCommand = {
      userId: userData.id,
      permissions: permissionsIds,
    };

    try {
      await editHaAdminUserTrigger({ body: requestBody });
      toastNotify(t("adminEdit.successToast", { user: userData.email }), "success");
      navigateHook("/usermanagement/haadmins");
    } catch (error: any) {
      setErrorMessage(getServerErrorMessage(error, t("adminEdit.requestErrorMessage")));
    }
  };

  const cancelCreate = async () => {
    navigateHook("/usermanagement/haadmins");
  };

  useEffect(() => {
    if (data?.permissions && getValues("permissions").length === 0) {
      reset({
        permissions: data!.permissions.map((perm) => ({
          id: perm.id,
          key: perm.key,
          displayText: t<string>(`adminUser.permissions.${perm.key}`),
          selected: userData.permissions.includes(perm.key),
        })),
      });
    }
  }, [reset, data, getValues, t, userData.permissions]);

  return (
    <div>
      <PageHeader title={t("adminEdit.ha.title")} subtitle={t("adminEdit.ha.subtitle")} />
      <Form
        className="mt-4"
        onSubmit={onSubmit}
        onCancel={cancelCreate}
        validationTrigger={trigger}
        submitButtonText={t("adminEdit.saveButton")}
        disableSubmit={isEditingSelf}
      >
        <div className="w-1/2 mb-4">
          <Input id="emailAddress" aria-label={t("adminEdit.ariaEmail")} type="text" readOnly={true} value={userData.email} />
        </div>

        <Controller
          control={control}
          name="permissions"
          rules={{ validate: () => getValues("permissions").some((p) => p.selected) }}
          render={({ field: { value, onChange } }) => {
            return getValues("permissions").length === 0 ? (
              <></>
            ) : (
              <div>
                <CheckboxGrid
                  items={value}
                  dependencies={haAdminPermissionDependencies}
                  headingText={t("adminEdit.permissions.selectPermissions")}
                  onChange={(state) => onChange(state)}
                  disabled={isEditingSelf}
                />
              </div>
            );
          }}
        />
        {isEditingSelf && (
          <div className="flex gap-2 mt-8 items-center w-fit">
            <InfoIcon className="text-primary-700 w-[2rem] h-[2rem]" />
            <p>{t("adminEdit.ha.editingSelfInfoMessage")}</p>
          </div>
        )}

        {errors.permissions && <ErrorAlert content={t("adminEdit.noPermissionsSelectedError")} />}
        {permissionError && <ErrorAlert content={t("adminEdit.permissions.error")} />}
      </Form>
      <ErrorAlert content={errorMessage} />
    </div>
  );
};
