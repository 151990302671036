import { RoleKeys } from "core/Constants";
import { AuthorisationProperties } from "core/state/AdminUserContext";

const RouteAuthorisation = {
  dashboard: [
    { role: RoleKeys.HaAdmin, permissions: ["view-reports"] },
    { role: RoleKeys.ClientAdmin, permissions: ["view-reports"] },
  ] as AuthorisationProperties[],
  viewHaAdmins: [{ role: RoleKeys.HaAdmin, permissions: ["view-ha-admins"] }] as AuthorisationProperties[],
  viewClientAdmins: [{ role: RoleKeys.HaAdmin, permissions: ["view-client-admins"] }] as AuthorisationProperties[],
  viewWisdomUsers: [
    { role: RoleKeys.HaAdmin, permissions: ["view-users"] },
    { role: RoleKeys.ClientAdmin, permissions: ["view-users"] },
  ] as AuthorisationProperties[],
  viewClients: [{ role: RoleKeys.HaAdmin, permissions: ["view-clients"] }] as AuthorisationProperties[],
  targetGroups: [{ role: RoleKeys.HaAdmin, permissions: ["view-clients"] }] as AuthorisationProperties[],
  importClients: [{ role: RoleKeys.HaAdmin, permissions: ["add-clients", "edit-clients"] }] as AuthorisationProperties[],
  dualBranding: [{ role: RoleKeys.ClientAdmin }] as AuthorisationProperties[],
  sendPushNotifications: [{ role: RoleKeys.HaAdmin, permissions: ["send-push-notifications"] }] as AuthorisationProperties[],
} as const;

export default RouteAuthorisation;
