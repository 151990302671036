import { createContext, PropsWithChildren, useContext, useMemo, useState } from "react";
import { AdminUserResponseModel } from "shared/request/myHealthyAdvantageApi";
import { AdminUserState } from "./adminUserState";
import { RoleKeys } from "../Constants";

export type AuthorisationProperties = {
  role?: RoleKeys | undefined;
  permissions?: string[] | undefined;
};

export type AdminUserContextType = {
  user: AdminUserResponseModel | undefined;
  saveUser: (user: AdminUserResponseModel) => void;
  isAuthorised(authProperties: AuthorisationProperties): boolean;
  needsToCompleteAdditionalInfo: (user?: AdminUserResponseModel) => boolean;
};

const AdminUserContext = createContext<AdminUserContextType>({} as AdminUserContextType);

export const useAdminUserContext = () => useContext(AdminUserContext);

export const AdminUserContextProvider = (props: PropsWithChildren) => {
  const [user, setUser] = useState<AdminUserResponseModel | undefined>(undefined);

  if (!user && AdminUserState.get()) {
    setUser(AdminUserState.get());
  }

  const saveUser = (user: AdminUserResponseModel) => {
    setUser(user);
    AdminUserState.set(user);
  };

  const userContextValue = useMemo(() => {
    const isAuthorised = ({ role, permissions }: AuthorisationProperties): boolean => {
      const isRoleAuthorised = !role || role === user?.role;
      const isPermissionAuthorised = !permissions || permissions.every((permission) => user?.permissions.includes(permission));
      return isRoleAuthorised && isPermissionAuthorised;
    };

    const needsToCompleteAdditionalInfo = (adminUser?: AdminUserResponseModel) => !!adminUser && (!adminUser.forenames || !adminUser.surname);

    return { user, saveUser, isAuthorised, needsToCompleteAdditionalInfo };
  }, [user]);

  return <AdminUserContext.Provider value={userContextValue}>{props.children}</AdminUserContext.Provider>;
};
