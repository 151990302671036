import Select from "@brighthr/component-select";
import { t } from "i18next";

export const PageSizeSelector = ({ onChanged, value }: { onChanged: Function, value: number }) => {
  return (
    <label className="flex items-center">
      <p className="mr-2">{t("pagination.pageSize")}</p>
      {
        <Select value={value} onChange={(e) => onChanged(Number(e.target.value))} aria-label={t("pagination.dropDown")}>
          <option value={10}>10</option>
          <option value={25}>25</option>
        </Select>
      }
    </label>
  );
};
