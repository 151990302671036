import StylableButton from "shared/UI/Buttons/StylableButton";
import "./Form.css";

type FormCancelButtonProps = {
  text: string;
  onClick: VoidFunction;
};

export const FormCancelButton = (props: FormCancelButtonProps) => {
  return (
    <StylableButton
      className="form-button form-button-cancel mr-4"
      aria-label={props.text}
      fullWidth
      color="accent"
      type="button"
      text={props.text}
      onClick={props.onClick}
    />
  );
};
