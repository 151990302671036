import { useTranslation } from "react-i18next";

export const SelectMenuOption = () => {
  const { t } = useTranslation();

  return (
    <p role="doc-subtitle" className="text-black">
      {t("selectMenuOption.subtitle")}
    </p>
  );
};
