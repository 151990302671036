import { useTranslation } from "react-i18next";
import { NavbarDropDown } from "./NavbarDropDown";
import { ReactComponent as HomeIcon } from "shared/assets/Icons/home.svg";
import { ReactComponent as AccountIcon } from "shared/assets/Icons/account.svg";
import { ReactComponent as ClientsIcon } from "shared/assets/Icons/clients.svg";
import { ReactComponent as DualBrandingIcon } from "shared/assets/Icons/settings.svg";
import { ReactComponent as PushNotificationsIcon } from "shared/assets/Icons/bell.svg";
import { ProtectedNavbarItem } from "./ProtectedNavbarItem";
import RouteAuthorisation from "core/auth/routeAuthorisation";

export const NavigationItems = (props: { isDrawerOpen: boolean }) => {
  const { t } = useTranslation();

  return (
    <>
      <ProtectedNavbarItem
        requiredAuthorisations={RouteAuthorisation.dashboard}
        href={"/dashboard"}
        name={t("dashboard.title")}
        Icon={HomeIcon}
        isExpanded={props.isDrawerOpen}
      />
      <NavbarDropDown
        title={t("navbarHeaders.users")}
        Icon={AccountIcon}
        isExpanded={props.isDrawerOpen}
        forAnyOf={[RouteAuthorisation.viewHaAdmins, RouteAuthorisation.viewClientAdmins, RouteAuthorisation.viewWisdomUsers]}
      >
        <ProtectedNavbarItem
          requiredAuthorisations={RouteAuthorisation.viewHaAdmins}
          href={"/usermanagement/haadmins"}
          name={t("haAdminManagement.navBarTitle")}
          Icon={null}
          isExpanded={props.isDrawerOpen}
        />
        <ProtectedNavbarItem
          requiredAuthorisations={RouteAuthorisation.viewClientAdmins}
          href={"/usermanagement/clientadmins"}
          name={t("clientAdminManagement.navBarTitle")}
          Icon={null}
          isExpanded={props.isDrawerOpen}
        />
        <ProtectedNavbarItem
          requiredAuthorisations={RouteAuthorisation.viewWisdomUsers}
          href={"/usermanagement/wisdomusers"}
          name={t("userManagement.navBarTitle")}
          Icon={null}
          isExpanded={props.isDrawerOpen}
        />
      </NavbarDropDown>
      <NavbarDropDown
        title={t("navbarHeaders.clients")}
        Icon={ClientsIcon}
        isExpanded={props.isDrawerOpen}
        forAnyOf={[RouteAuthorisation.viewClients, RouteAuthorisation.targetGroups, RouteAuthorisation.importClients]}
      >
        <ProtectedNavbarItem
          requiredAuthorisations={RouteAuthorisation.viewClients}
          href={"/clientmanagement"}
          name={t("clientManagement.navBarTitle")}
          Icon={null}
          isExpanded={props.isDrawerOpen}
        />
        <ProtectedNavbarItem
          requiredAuthorisations={RouteAuthorisation.targetGroups}
          href={"/targetgroups"}
          name={t("targetGroups.navBarTitle")}
          Icon={null}
          isExpanded={props.isDrawerOpen}
        />
        <ProtectedNavbarItem
          requiredAuthorisations={RouteAuthorisation.importClients}
          href={"/importclients"}
          name={t("importClients.navBarTitle")}
          Icon={null}
          isExpanded={props.isDrawerOpen}
        />
      </NavbarDropDown>
      <ProtectedNavbarItem
        requiredAuthorisations={RouteAuthorisation.dualBranding}
        href={"/dualbranding"}
        name={t("dualBranding.navbarTitle")}
        Icon={DualBrandingIcon}
        isExpanded={props.isDrawerOpen}
      />
      <ProtectedNavbarItem
        requiredAuthorisations={RouteAuthorisation.sendPushNotifications}
        href={"/pushnotifications"}
        name={t("pushNotifications.navBarTitle")}
        Icon={PushNotificationsIcon}
        isExpanded={props.isDrawerOpen}
      />
    </>
  );
};
