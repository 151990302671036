import StylableButton from "shared/UI/Buttons/StylableButton";
import { useTranslation } from "react-i18next";

export const ExportReport = () => {
  const { t } = useTranslation();
  return (
    <StylableButton
      text={t("dashboard.export")}
      color="primary"
      type="button"
      onClick={() => document.dispatchEvent(new CustomEvent("exportReport", {}))}
    />
  );
};
