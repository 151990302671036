import { Navbar } from "./components/Navbar/Navbar";
import { Outlet } from "react-router-dom";

export const AppLayout = () => {
  return (
    <div className="flex flex-col md:flex-row">
      <Navbar />
      <main className="w-full p-4">
        <Outlet></Outlet>
      </main>
    </div>
  );
};
