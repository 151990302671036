import { useTranslation } from "react-i18next";
import { UseFormTrigger } from "react-hook-form";
import { FormCancelButton } from "./FormCancelButton";
import { FormSubmitButton } from "./FormSubmitButton";
import { PropsWithChildren } from "react";

export type FormProps = PropsWithChildren & {
  onSubmit: VoidFunction;
  onCancel: VoidFunction;
  className: string | undefined;
  validationTrigger: UseFormTrigger<any>;
  disableSubmit?: boolean;
  cancelButtonText?: string;
  submitButtonText?: string;
};

export const Form = ({
  onSubmit,
  onCancel,
  validationTrigger,
  className,
  cancelButtonText,
  disableSubmit = false,
  submitButtonText,
  children,
}: FormProps) => {
  const { t } = useTranslation();
  const handleSubmit = async () => {
    const isFormValid = await validationTrigger();

    if (isFormValid) {
      onSubmit();
    }
  };

  return (
    <form className={className} onSubmit={(e) => e.preventDefault()}>
      {children}
      <div className="mt-8 flex">
        <FormCancelButton text={cancelButtonText ?? t("form.defaults.cancelButton")} onClick={onCancel} />
        <FormSubmitButton disabled={disableSubmit} text={submitButtonText ?? t("form.defaults.submitButton")} onClick={handleSubmit} />
      </div>
    </form>
  );
};
