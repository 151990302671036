import "./DualBranding.css";
import { PageHeader } from "../../UIPalette/components/PageHeader/PageHeader";
import { t } from "i18next";
import { useAdminUserContext } from "core/state/AdminUserContext";
import {
  ClientResponseModel,
  useDeleteImageFunctionRequest,
  useGetClientFunctionRequest,
  useUploadImageFunctionRequest,
} from "shared/request/myHealthyAdvantageApi";
import { ClientLogo } from "shared/UI/ClientLogo/ClientLogo";
import React, { useState } from "react";
import ImageUploading, { ImageListType, ImageType } from "react-images-uploading";
import { ReactComponent as UploadIcon } from "shared/assets/Icons/upload.svg";
import ErrorAlert from "shared/UI/Alerts/ErrorAlert";
import StylableButton from "shared/UI/Buttons/StylableButton";
import { ConfirmationModal } from "shared/UI/Modal/ConfirmationModal/ConfirmationModal";

const DualBranding = () => {
  const { user } = useAdminUserContext();
  const clientId = user?.client?.id;
  const { data: client, mutate } = useGetClientFunctionRequest({ clientId });
  const { trigger: uploadImageAPI } = useUploadImageFunctionRequest({ clientCode: client?.code }, {});
  const [logoFile, setLogoFile] = useState<ImageListType>([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { trigger: deleteImageAPI } = useDeleteImageFunctionRequest();

  async function saveOnClick() {
    const formData = new FormData();
    formData.append("logo", logoFile[0].file!);
    await uploadImageAPI({ body: formData });
    setLogoFile([]);
    await mutate();
  }

  async function onDeleteButtonClick() {
    if (client !== undefined) {
      await deleteImageAPI({ query: { clientCode: client.code, fileName: client.customLogo } });
      await mutate();
    }
    setShowDeleteModal(false);
  }

  return (
    <>
      <PageHeader title={t("dualBranding.title")} subtitle={t("dualBranding.subtitle")} />
      <div className={"font-bold mt-8"}>Choose an image</div>

      <div className="w-1/2" data-testid="upload-logo-container">
        <FileSelector images={logoFile} setImages={setLogoFile} client={client} onDeleteCurrentLogoClick={() => setShowDeleteModal(true)} />
      </div>

      <div className="flex gap-1 justify-end mt-8">
        <StylableButton
          className="save-button bright-button "
          color="primary"
          text={t("dualBranding.saveButton")}
          size={"xl"}
          fullWidth
          disabled={logoFile.length === 0}
          onClick={saveOnClick}
        />
      </div>
      {showDeleteModal && (
        <ConfirmationModal
          title={t("dualBranding.deleteModal.title")}
          message={t("dualBranding.deleteModal.message")}
          cancelButtonText={t("dualBranding.deleteModal.cancel")}
          confirmButtonText={t("dualBranding.deleteModal.confirm")}
          onCancel={() => setShowDeleteModal(false)}
          onConfirm={onDeleteButtonClick}
        />
      )}
    </>
  );
};

interface FileSelectorProps {
  images: Array<ImageType>;
  setImages: (value: ((prevState: Array<ImageType>) => Array<ImageType>) | Array<ImageType>) => void;
  client: ClientResponseModel | undefined;
  onDeleteCurrentLogoClick: () => void;
}

const FileSelector = ({ images, setImages, client, onDeleteCurrentLogoClick }: FileSelectorProps) => {
  const onChange = (imageList: ImageListType) => {
    setImages(imageList as never[]);
  };

  const hasClientLogo = client?.customLogo !== null && client?.customLogo !== "";

  const mbLimit = 10;
  const maxFileSizeBytes = 1000000 * mbLimit;
  return (
    <>
      <ImageUploading value={images} onChange={onChange} maxNumber={1} maxFileSize={maxFileSizeBytes} acceptType={["png", "jpg", "jpeg"]}>
        {({ imageList, onImageUpload, onImageUpdate, onImageRemove, isDragging, dragProps, errors }) => (
          <div className="">
            {errors?.maxFileSize && <ErrorAlert content={t("dualBranding.errors.maxFileSize", { limit: mbLimit })} />}
            {imageList.length === 0 && !hasClientLogo ? (
              <button
                className="p-8 w-full rounded-lg border-dashed border-2 border-sky-grey hover:border-black transition-all"
                style={
                  isDragging
                    ? {
                        borderColor: "black",
                        backgroundColor: "#E5E5E5",
                      }
                    : undefined
                }
                onClick={onImageUpload}
                data-testid="upload-button"
                {...dragProps}
              >
                <div className="flex justify-center align-middle flex-col pointer-events-none">
                  <UploadIcon className={"w-full mb-6 h-[4em]"} />
                  <div className="mb-4">{t("dualBranding.dropZoneText")}</div>
                  <div className="text-l-grey">{t("dualBranding.dragZoneFileLimitations", { limit: mbLimit })}</div>
                </div>
              </button>
            ) : (
              <>
                {client !== undefined && imageList.length === 0 ? (
                  <>
                    <ClientLogo
                      clientCode={client.code}
                      imageFilename={client.customLogo}
                      alt={t("dualBranding.clientLogoAlt", { clientName: client.name })}
                      imgClassNames={"max-h-[18rem]"}
                    />
                    <div className="flex gap-1 justify-between mt-8">
                      <StylableButton
                        className="logo-edit-buttons"
                        text={t("dualBranding.changeImageButton")}
                        fullWidth
                        color="primary"
                        onClick={onImageUpload}
                      />
                      <StylableButton
                        className="logo-edit-buttons"
                        text={t("dualBranding.removeImageButton")}
                        color="primary"
                        fullWidth
                        outlineButton
                        onClick={onDeleteCurrentLogoClick}
                      />
                    </div>
                  </>
                ) : null}
              </>
            )}
            {imageList.map((image, index) => (
              <div key={image.dataURL} className="flex flex-col justify-center align-middle">
                <img className="w-100 m-auto max-h-[18rem]" src={image.dataURL} alt={t("dualBranding.clientLogoAlt", { clientName: client?.name })} />
                <div className="flex justify-between mt-4">
                  <StylableButton
                    className="logo-edit-buttons"
                    color="primary"
                    onClick={() => onImageUpdate(index)}
                    fullWidth
                    text={t("dualBranding.changeImageButton")}
                  />
                  <StylableButton
                    className="logo-edit-buttons"
                    color="primary"
                    onClick={() => onImageRemove(index)}
                    outlineButton
                    fullWidth
                    text={t("dualBranding.removeImageButton")}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
      </ImageUploading>
    </>
  );
};

export default DualBranding;
