import { Route, Routes } from "react-router-dom";

import { CreateClient } from "./View/Create/CreateClient";
import { EditClient } from "./View/Edit/EditClient";
import { ViewClients } from "./View/ViewClients";

export const ClientManagement = () => {
  return (
    <Routes>
      <Route index element={<ViewClients />} />
      <Route path="create" element={<CreateClient />} />
      <Route path="edit" element={<EditClient />} />
    </Routes>
  );
};
