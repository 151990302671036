import { useTranslation } from "react-i18next";
import { EditAdminUserAdditionalInfoCommand, useEditAdminUserAdditionalInfoFunctionRequest } from "shared/request/myHealthyAdvantageApi";
import { Controller, useForm } from "react-hook-form";
import ErrorAlert from "shared/UI/Alerts/ErrorAlert";
import StylableButton from "shared/UI/Buttons/StylableButton";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DestinationState } from "shared/core/state/destinationState";
import { AdminUserState } from "core/state/adminUserState";
import { useAdminUserContext } from "core/state/AdminUserContext";
import { PageHeader } from "../../UIPalette/components/PageHeader/PageHeader";
import { LabelledInput } from "shared/UI/Textboxes/LabelledInput/LabelledInput";

type Inputs = {
  firstName: string;
  lastName: string;
};

export const AdditionalInfo = () => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState(false);
  const storedUser = AdminUserState.get();
  const { trigger } = useEditAdminUserAdditionalInfoFunctionRequest();
  const navigate = useNavigate();
  const { saveUser, needsToCompleteAdditionalInfo, user } = useAdminUserContext();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      firstName: storedUser?.forenames ?? "",
      lastName: storedUser?.surname ?? "",
    },
  });

  const onSubmit = async (additionalInfo: Inputs) => {
    setErrorMessage(false);
    const fullData: EditAdminUserAdditionalInfoCommand = {
      forenames: additionalInfo.firstName,
      surname: additionalInfo.lastName,
    };

    try {
      const response = await trigger({ body: fullData });
      saveUser(response!.data);
      navigate(DestinationState.get());
    } catch {
      setErrorMessage(true);
    }
  };

  useEffect(() => {
    if (!needsToCompleteAdditionalInfo(user)) {
      navigate(DestinationState.get());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, needsToCompleteAdditionalInfo]);

  const inputWrapperClasses = "mt-4";

  return (
    <>
      <PageHeader title={t("additionalInfo.heading")} subtitle={""} />
      <form className={"w-2/5"} onSubmit={handleSubmit(onSubmit)}>
        <div className={inputWrapperClasses}>
          <Controller
            name={"firstName"}
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <>
                <LabelledInput type="text" id="firstName" onChange={onChange} value={value} label={t("additionalInfo.firstName")} />
                {errors.firstName && <ErrorAlert content={t("additionalInfo.firstNameErrorMessage")} />}
              </>
            )}
          />
        </div>

        <div className={inputWrapperClasses}>
          <Controller
            name={"lastName"}
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <>
                <LabelledInput type="text" id="lastName" onChange={onChange} value={value} label={t("additionalInfo.lastName")} />
                {errors.lastName && <ErrorAlert content={t("additionalInfo.lastNameErrorMessage")} />}
              </>
            )}
          />
        </div>

        <div className={inputWrapperClasses}>
          <StylableButton
            className="[&>*]:mt-6"
            color="primary"
            type="submit"
            size={"xl"}
            text={t("additionalInfo.submit")}
            aria-label={t("additionalInfo.submit")}
          />
        </div>
      </form>

      {errorMessage && <ErrorAlert content={t("additionalInfo.requestErrorMessage")} />}
    </>
  );
};
