import { ChartProps } from "./ChartProps";
import { useTranslation } from "react-i18next";
import { useGetReport } from "./Hooks/useGetReport";
import ErrorAlert from "shared/UI/Alerts/ErrorAlert";
import ViewLoading from "shared/UI/Spinners/ViewLoading";
import { Pie } from "react-chartjs-2";
import { ChartColors, pieChartOptions } from "./ChartConstants";
import { DataDimension } from "shared/request/myHealthyAdvantageApi";
import { ArcElement, Chart as ChartJS } from "chart.js";
import { formatDataPoint } from "./dimensionLabelFormatter";
import { useCallback, useEffect } from "react";
import { PieChartExporter } from "./Export/export";

ChartJS.register(ArcElement);

export const PieChart = (chartProps: ChartProps) => {
  const { t } = useTranslation();
  const { data, isLoading, error } = useGetReport(chartProps);
  const exportData = useCallback(
    () => chartProps.dataExporter.export(data, chartProps.filters.dateFrom, chartProps.filters.dateTo),
    [data, chartProps]
  );

  useEffect(() => {
    document.addEventListener("exportReport", exportData);
    return () => {
      document.removeEventListener("exportReport", exportData);
    };
  }, [exportData]);

  if (error) {
    return <ErrorAlert title={t("charts.requestErrorTitle")} content={t("charts.requestErrorBody")} />;
  }

  if (isLoading) {
    return <ViewLoading />;
  }

  if (data?.dataDimensions === undefined || data.dataDimensions.length <= 0) {
    return <p>{t("charts.requestNoData")}</p>;
  }

  const dimension = data.dataDimensions![0];

  const labels = formatLabels(dimension);
  const dataset = mapDataset(dimension, chartProps.colours);

  return (
    <Pie
      data={{
        labels: labels,
        datasets: dataset,
      }}
      options={pieChartOptions}
    />
  );
};

function formatLabels(dimension: DataDimension) {
  return dimension.value!.map((dataPoint) => formatDataPoint(dataPoint));
}

function mapDataset(dimension: DataDimension, colours: Record<string, string> | undefined) {
  const filteredColours = getColours(dimension, colours);

  return [
    {
      label: dimension.key!,
      data: dimension.value?.map((dataPoint) => dataPoint.value),
      borderColor: "#FFFFFF",
      backgroundColor: filteredColours,
    },
  ];
}

function getColours(dimension: DataDimension, colours: Record<string, string> | undefined) {
  const labels = getLabels(dimension);
  return colours === undefined ? ChartColors : labels.map((label) => colours![label!]);
}

function getLabels(dimension: DataDimension) {
  return dimension.value!.map((dataPoint) => dataPoint.key);
}

PieChart.defaultProps = {
  colours: undefined,
  dataExporter: new PieChartExporter(),
  translatedLabels: false,
};
