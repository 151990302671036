import "./index.css";
import "./i18n";
import "shared/core/types/authStorage";

import { App } from "./App";
import { AuthProvider } from "react-oidc-context";
import { BrowserRouter } from "react-router-dom";
import React from "react";
import ReactDOM from "react-dom/client";
import { WebStorageStateStore } from "oidc-client-ts";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

window.authStorage = sessionStorage;
const redirectUri = `${window.location.origin}/auth`;
const authProviderConfig = {
  authority: process.env.REACT_APP_AUTH_AUTHORITY!,
  client_id: process.env.REACT_APP_AUTH_CLIENT_ID!,
  redirect_uri: redirectUri,
  automaticSilentRenew: true,
  silent_redirect_uri: redirectUri,
  scope: "openid",
  post_logout_redirect_uri: `${window.location.origin}/login`,
  userStore: new WebStorageStateStore({
    store: window.authStorage,
  }),
};

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider {...authProviderConfig}>
        <App />
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);
