import { ChangeEventHandler, useId } from "react";
import { Select } from "../UI/Inputs/Select/Select";
import { useDefaultLocaleId } from "../core/hooks/useDefaultLocaleList";
import { useListLocaleFunctionRequest } from "../request/myHealthyAdvantageApi";

type LocaleSelectProps = {
  label?: string;
  onChange: ChangeEventHandler<HTMLSelectElement>;
  value?: string;
  ariaLabel: string;
  height?: string;
  width?: string;
};

export const LocaleSelect = ({ label, onChange, value, ariaLabel, height, width }: LocaleSelectProps) => {
  const { data: localeList, isLoading, error } = useListLocaleFunctionRequest();
  const defaultLocale = useDefaultLocaleId();
  const id = useId();
  const labelId = `select-${id}`;

  return localeList && !isLoading && !error ? (
    <div className="relative w-full">
      <Select
        label={label}
        id={labelId}
        value={value || localeList?.locales?.[0]?.id || defaultLocale}
        onChange={onChange}
        height={height ?? "h-[42px] font-semibold text-neutral-700"}
        width={width}
        aria-label={ariaLabel}
      >
        {localeList?.locales &&
          localeList.locales.map(({ id, description }) => (
            <option key={id} value={id}>
              {description}
            </option>
          ))}
      </Select>
    </div>
  ) : null;
};
