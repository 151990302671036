import { CheckboxGridDependency } from "../UIPalette/components/CheckboxGrid/CheckboxGrid";
import i18n from "i18next";

export const haAdminPermissionDependencies: CheckboxGridDependency[] = [
  {
    key: "view-users",
    requiredMessage: i18n.t("adminUser.permissions.requiredMessages.view-users"),
    dependentKeys: ["edit-users", "delete-users"],
  },
  {
    key: "view-clients",
    requiredMessage: i18n.t("adminUser.permissions.requiredMessages.view-clients"),
    dependentKeys: ["add-clients", "edit-clients", "delete-clients"],
  },
  {
    key: "view-ha-admins",
    requiredMessage: i18n.t("adminUser.permissions.requiredMessages.view-ha-admins"),
    dependentKeys: ["add-ha-admins", "edit-ha-admins", "delete-ha-admins"],
  },
  {
    key: "view-client-admins",
    requiredMessage: i18n.t("adminUser.permissions.requiredMessages.view-client-admins"),
    dependentKeys: ["add-client-admins", "edit-client-admins", "delete-client-admins"],
  },
];

export const clientAdminPermissionDependencies: CheckboxGridDependency[] = [
  {
    key: "view-users",
    requiredMessage: i18n.t("adminUser.permissions.requiredMessages.view-users"),
    dependentKeys: ["delete-users"],
  },
];
