type PageHeaderProps = {
  title:string,
  subtitle:string
}

export const PageHeader = ({title, subtitle}:PageHeaderProps) =>{
  return (
    <>
      <h2 className="text-black mb-4">{title}</h2>
      <p role="doc-subtitle" className="text-black">
        {subtitle}
      </p>
    </>
  );
}