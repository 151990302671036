import "./LogoutButton.css";
import { useAuth } from "core/auth/useAuth";
import { ButtonProps } from "@brighthr/component-button";
import { t } from "i18next";
import StylableButton from "shared/UI/Buttons/StylableButton";
import { toastNotify } from "shared/UI/Toaster/Toaster";

interface LogoutButtonProps extends ButtonProps {
  className?: string;
}

export const LogoutButton = ({ className, ...rest }: LogoutButtonProps) => {
  const auth = useAuth();

  function logout() {
    auth.signoutRedirect().catch(() => {
      toastNotify(t("userProfile.logoutErrMsg"), "error");
    });
  }

  return <StylableButton textButton={true} text={t("userProfile.logout")} className={`red-text text-sm ${className}`} onClick={logout} {...rest} />;
};
