import { useTranslation } from "react-i18next";
import { PageHeader } from "../../../UIPalette/components/PageHeader/PageHeader";
import {
  AdminUsersResponseModel,
  ListAdminUsersQuery,
  useDeleteClientAdminUserFunctionRequest,
  useListAdminUsersFunctionRequest,
} from "shared/request/myHealthyAdvantageApi";
import { useEffect, useState } from "react";
import { RowMenuItem, Table, TableHeader } from "../../../UIPalette/components/Table/Table";
import ErrorAlert from "shared/UI/Alerts/ErrorAlert";
import { OrderByDirection, RoleKeys, Roles } from "core/Constants";
import { useDebounce } from "shared/core/hooks/useDebounce";
import Input from "@brighthr/component-input";
import { ClientDivisionSelection, ClientDivisionSelector } from "../../../UIPalette/components/ClientDivisionSelector/ClientDivisionSelector";
import StylableButton from "shared/UI/Buttons/StylableButton";
import "shared/extensions/string";
import { useNavigate } from "react-router-dom";
import { useAdminUserContext } from "core/state/AdminUserContext";
import { toastNotify } from "shared/UI/Toaster/Toaster";
import { ListAdminUsersOrderBy } from "./ListAdminUsersOrderBy";
import { ConfirmationModal } from "shared/UI/Modal/ConfirmationModal/ConfirmationModal";

export const ViewClientAdmins = () => {
  const { t } = useTranslation();
  const navigateHook = useNavigate();
  const { trigger: listAdminUsersRequest } = useListAdminUsersFunctionRequest();
  const { trigger: deleteAdminUserRequest } = useDeleteClientAdminUserFunctionRequest();
  const [userResponse, setUserResponse] = useState<AdminUsersResponseModel>();
  const [errorMessage, setErrorMessage] = useState("");
  const [ordering, setOrdering] = useState({
    column: ListAdminUsersOrderBy.CreatedDateUtc,
    direction: OrderByDirection.Descending,
  });
  const [paging, setPaging] = useState({ current: 1, size: 10 });
  const [clientDivisionFilter, setClientDivisionFilter] = useState<ClientDivisionSelection>();
  const [keywordFilter, setKeywordFilter] = useState<string>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedAdminUserIndex, setSelectedAdminUserIndex] = useState(0);
  const debouncedKeyword = useDebounce(keywordFilter, 500);
  const { isAuthorised } = useAdminUserContext();

  const tableHeaders: TableHeader[] = [
    {
      text: t("clientAdminManagement.table.nameHeading"),
      orderByValue: ListAdminUsersOrderBy.Forenames,
    },
    {
      text: t("clientAdminManagement.table.surnameHeading"),
      orderByValue: ListAdminUsersOrderBy.Surname,
    },
    {
      text: t("clientAdminManagement.table.emailHeading"),
      orderByValue: ListAdminUsersOrderBy.Email,
    },
    {
      text: t("clientAdminManagement.table.clientHeading"),
      orderByValue: ListAdminUsersOrderBy.Client,
    },
    {
      text: t("clientAdminManagement.table.divisionHeading"),
      orderByValue: ListAdminUsersOrderBy.Division,
    },
    {
      text: t("clientAdminManagement.table.statusHeading"),
      orderByValue: ListAdminUsersOrderBy.IsActive,
    },
    {
      text: t("clientAdminManagement.table.creationDateHeading"),
      orderByValue: ListAdminUsersOrderBy.CreatedDateUtc,
      defaultOrderDirection: OrderByDirection.Descending,
    },
  ];

  const buildRowMenuItems = () => {
    let rowMenuItems: RowMenuItem[] = [];

    if (isAuthorised({ role: RoleKeys.HaAdmin, permissions: ["delete-client-admins"] })) {
      rowMenuItems.push({
        title: t("table.deleteTitle"),
        ariaLabel: t("table.ariaDeleteRow"),
        onClick: (rowIndex: number) => onDeleteAdminUser(rowIndex),
      });
    }

    if (isAuthorised({ role: RoleKeys.HaAdmin, permissions: ["edit-client-admins"] })) {
      rowMenuItems.push({
        title: t("table.editTitle"),
        ariaLabel: t("table.ariaEditRow"),
        onClick: (rowIndex: number) => onEditAdminUser(rowIndex),
      });
    }

    return rowMenuItems;
  };

  const mapTableData = () =>
    userResponse?.records?.map(({ forenames, surname, email, client, division, isActive, ...rest }) => {
      return {
        forenames,
        surname,
        email,
        client: client?.name,
        division: division?.name,
        status: isActive ? t("haAdminManagement.status.active") : t("haAdminManagement.status.pending"),
        createdDate: String.formatDateTime(rest.createdDate),
      };
    });

  const deleteAdminUser = async () => {
    try {
      const userId = userResponse!.records![selectedAdminUserIndex].id;
      await deleteAdminUserRequest({ query: { id: userId } });
      const updatedRecords = userResponse?.records?.filter((record) => record.id !== userId);
      setUserResponse({ ...userResponse, records: updatedRecords });
      toastNotify(t("adminDelete.toastMessage"), "success");
    } catch {
      setErrorMessage(t("adminDelete.client.requestErrorMessage"));
    } finally {
      setShowDeleteModal(false);
    }
  };

  useEffect(() => {
    async function listClientAdminUsers() {
      const requestBody: ListAdminUsersQuery = {
        roleId: Roles.ClientAdmin,
        page: paging.current,
        perPage: paging.size,
        orderBy: ordering.column,
        orderByDirection: ordering.direction,
        filter: {
          keyword: debouncedKeyword,
          clientId: clientDivisionFilter?.clientId,
          divisionId: clientDivisionFilter?.divisionId,
        },
      };
      try {
        setErrorMessage("");
        const response = await listAdminUsersRequest({ body: requestBody });
        setUserResponse(response?.data);
      } catch {
        setErrorMessage(t("clientAdminManagement.requestErrorMessage"));
      }
    }

    listClientAdminUsers();
  }, [debouncedKeyword, listAdminUsersRequest, paging, ordering, clientDivisionFilter, t]);

  const onDeleteAdminUser = async (rowIndex: number) => {
    setSelectedAdminUserIndex(rowIndex);
    setShowDeleteModal(true);
  };

  const onEditAdminUser = async (rowIndex: number) => {
    navigateHook("/usermanagement/clientadmins/edit", { state: userResponse!.records![rowIndex] });
  };

  return (
    <>
      <PageHeader title={t("clientAdminManagement.title")} subtitle={t("clientAdminManagement.subtitle")} />
      <ClientDivisionSelector onSelection={(clientDivisionFilters) => setClientDivisionFilter(clientDivisionFilters)} />
      <Input
        aria-label={t("clientAdminManagement.ariaSearch")}
        onChange={(a) => setKeywordFilter(a.target.value)}
        rightIconName="search"
        placeholder={t("clientAdminManagement.searchPlaceholder")}
      ></Input>

      <Table
        headers={tableHeaders}
        dataRows={mapTableData() ?? []}
        orderedByColumn={ordering.column}
        orderedDirection={ordering.direction}
        currentPage={paging.current}
        pageSize={paging.size}
        totalDataCount={userResponse?.metadata?.totalCount ?? 0}
        onOrderingChange={setOrdering}
        onPagingChange={setPaging}
        actionButtons={
          <StylableButton className="rounded-button" color="primary" text={"+"} onClick={() => navigateHook("/usermanagement/clientadmins/create")} />
        }
        rowMenuItems={buildRowMenuItems()}
      />
      {showDeleteModal && (
        <ConfirmationModal
          title={t("adminDelete.client.modalTitle")}
          message={t("adminDelete.modalMessage", { userEmail: userResponse!.records![selectedAdminUserIndex].email })}
          cancelButtonText={t("adminDelete.modalDismissTitle")}
          confirmButtonText={t("adminDelete.modalConfirmTitle")}
          onCancel={() => {
            setShowDeleteModal(false);
          }}
          onConfirm={deleteAdminUser}
        />
      )}
      <ErrorAlert content={errorMessage} />
    </>
  );
};
