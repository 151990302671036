import Input from "@brighthr/component-input";
import { useTranslation } from "react-i18next";
import { useCreateClientAdminUserFunctionRequest, useListAdminPermissionsFunctionRequest } from "shared/request/myHealthyAdvantageApi";
import { Roles } from "core/Constants";
import { PageHeader } from "../../../UIPalette/components/PageHeader/PageHeader";
import ErrorAlert from "shared/UI/Alerts/ErrorAlert";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ClientDivisionSelector } from "../../../UIPalette/components/ClientDivisionSelector/ClientDivisionSelector";
import { emailRegex } from "shared/utils/regex";
import { Form } from "../../../UIPalette/components/Form/Form";
import { getServerErrorMessage } from "shared/utils/serverError";
import { CheckboxGrid, CheckboxGridItem } from "../../../UIPalette/components/CheckboxGrid/CheckboxGrid";
import { clientAdminPermissionDependencies } from "../../adminUserPermissions";
import { toastNotify } from "shared/UI/Toaster/Toaster";

type Inputs = {
  emailAddress: string;
  clientDivision: { clientId: string; divisionId: string };
  permissions: CheckboxGridItem[];
};

export const CreateClientAdmin = () => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const { data, error: permissionError } = useListAdminPermissionsFunctionRequest({ role: Roles.ClientAdmin });

  const { trigger: createClientAdminTrigger } = useCreateClientAdminUserFunctionRequest();
  const navigateHook = useNavigate();
  const {
    control,
    formState: { errors },
    getValues,
    reset,
    trigger,
  } = useForm<Inputs>({
    defaultValues: {
      emailAddress: "",
      clientDivision: { clientId: undefined, divisionId: undefined },
      permissions: [],
    },
  });

  const onSubmit = async () => {
    setErrorMessage("");

    const selectedPermissions = getValues("permissions").filter((p) => p.selected);
    const permissionsIds = selectedPermissions.map(function (v) {
      return v.id;
    });

    try {
      await createClientAdminTrigger({
        body: { emailAddress: getValues("emailAddress"), permissions: permissionsIds, ...getValues("clientDivision") },
      });
      toastNotify(t("adminCreate.successToast", { user: getValues("emailAddress") }), "success");
      navigateHook("/usermanagement/clientadmins");
    } catch (error: any) {
      setErrorMessage(getServerErrorMessage(error, t("adminCreate.requestErrorMessage")));
    }
  };

  useEffect(() => {
    if (data?.permissions && getValues("permissions").length === 0) {
      reset({
        permissions: data!.permissions.map((perm) => ({
          id: perm.id,
          key: perm.key,
          displayText: t<string>(`adminUser.permissions.${perm.key}`),
          selected: true,
        })),
      });
    }
  }, [reset, data, getValues, t]);

  return (
    <div>
      <PageHeader title={t("adminCreate.client.title")} subtitle={t("adminCreate.client.subtitle")} />
      <Form className="mt-4" onSubmit={onSubmit} onCancel={() => navigateHook("/usermanagement/clientadmins")} validationTrigger={trigger}>
        <Controller
          name={"emailAddress"}
          control={control}
          rules={{ required: true, pattern: emailRegex }}
          render={({ field: { onChange, value } }) => (
            <div className="my-4 content-center">
              <div className="flex flex-row mb-4 justify-between">
                <div className="w-1/2">
                  <Input
                    id="emailAddress"
                    aria-label={t("adminCreate.ariaEmail")}
                    placeholder={t("adminCreate.emailBoxPlaceholder")}
                    type="text"
                    onChange={onChange}
                    value={value}
                  />
                  {errors.emailAddress?.type === "pattern" && <ErrorAlert content={t("adminCreate.emailInvalidError")} />}
                  {errors.emailAddress?.type === "required" && <ErrorAlert content={t("adminCreate.emailRequiredError")} />}
                </div>
                <div className="w-1/2 ml-4"></div>
              </div>
            </div>
          )}
        />
        <Controller
          name={"clientDivision"}
          control={control}
          rules={{ validate: ({ clientId }) => !!clientId }}
          render={({ field: { onChange } }) => <ClientDivisionSelector className="mt-2" onSelection={onChange} />}
        />
        {errors.clientDivision && <ErrorAlert content={t("adminCreate.client.clientRequiredError")} />}

        <Controller
          control={control}
          name="permissions"
          rules={{ validate: () => getValues("permissions").some((p) => p.selected) }}
          render={({ field: { value, onChange } }) => {
            return getValues("permissions").length === 0 ? (
              <></>
            ) : (
              <CheckboxGrid
                items={value}
                dependencies={clientAdminPermissionDependencies}
                headingText={t("adminEdit.permissions.selectPermissions")}
                onChange={(state) => onChange(state)}
              />
            );
          }}
        />

        {errors.permissions && <ErrorAlert content={t("adminCreate.noPermissionsSelectedError")} />}
        {permissionError && <ErrorAlert content={t("adminCreate.permissions.error")} />}
      </Form>
      <ErrorAlert content={errorMessage} />
    </div>
  );
};
