import Checkbox from "@brighthr/component-checkbox";
import { ValueCallback } from "shared/core/types/callbacks";
import { useEffect, useState } from "react";
import "./CheckboxGrid.css";

export type CheckboxGridItem = {
  id: string;
  key: string;
  displayText: string;
  selected: boolean;
};

export type CheckboxGridDependency = {
  key: string;
  requiredMessage: string;
  dependentKeys: string[];
};

export type CheckboxGridProps = {
  headingText: string;
  items: CheckboxGridItem[];
  dependencies: CheckboxGridDependency[];
  disabled?: boolean;
  onChange: ValueCallback<CheckboxGridItem[]>;
};

export const CheckboxGrid = ({ headingText, items, dependencies, onChange, disabled = false }: CheckboxGridProps) => {
  const [itemsInternal, setItemsInternal] = useState(items);

  const isItemChecked = (key: string, index: number): boolean => {
    const [parentSelected] = isParentSelected(key);
    return parentSelected && itemsInternal[index].selected;
  };

  const isItemDisabled = (key: string): boolean => {
    const [parentSelected] = isParentSelected(key);
    return !parentSelected;
  };

  const getRequiredMessage = (key: string): string | undefined => {
    const [parentSelected, parentDependency] = isParentSelected(key);
    if (!parentSelected) {
      return parentDependency?.requiredMessage;
    }
    return undefined;
  };

  const isParentSelected = (key: string): [boolean, CheckboxGridDependency | undefined] => {
    const isParent = dependencies.find((d) => d.key === key) !== undefined;
    if (isParent) {
      return [true, undefined];
    }

    const parentDependency = dependencies.find((d) => d.dependentKeys.includes(key));
    if (!parentDependency) {
      return [true, undefined];
    }

    const parentItemIndex = itemsInternal.findIndex((item) => item.key === parentDependency.key);
    if (parentItemIndex === -1) {
      throw new Error(`Could not find parent checkbox item with key '${parentDependency.key}'`);
    }

    return [itemsInternal[parentItemIndex].selected, parentDependency];
  };

  const updateItemSelected = (key: string, index: number, selected: boolean) => {
    const updatedState = [...itemsInternal];
    updatedState[index].selected = selected;
    deselectDependentItems(key, selected, updatedState);
    setItemsInternal(updatedState);
  };

  const deselectDependentItems = (key: string, selected: boolean, state: CheckboxGridItem[]) => {
    const parentDependency = dependencies.find((d) => d.key === key);
    if (parentDependency && !selected) {
      parentDependency.dependentKeys.forEach((dependentKey) => {
        const dependentItemIndex = state.findIndex((item) => item.key === dependentKey);
        if (dependentItemIndex === -1) {
          throw new Error(`Could not find dependent checkbox item with key '${dependentKey}'`);
        }
        state[dependentItemIndex].selected = false;
      });
    }
  };

  useEffect(() => {
    onChange(itemsInternal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsInternal]);

  return (
    <div className="mt-2">
      <h4>{headingText}</h4>
      <div className="flex flex-wrap">
        {itemsInternal.map((item, index) => (
          <div className="basis-1/3 mt-4" key={item.id}>
            <Checkbox
              checked={isItemChecked(item.key, index)}
              disabled={disabled || isItemDisabled(item.key)}
              onChange={(e) => updateItemSelected(item.key, index, e.target.checked)}
              label={item.displayText}
              description={getRequiredMessage(item.key)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
