import { Controller, useForm } from "react-hook-form";
import { TargetGroupResponseModel, useSendPushNotificationFunctionRequest } from "shared/request/myHealthyAdvantageApi";

import { ClientDivisionSelector } from "../../UIPalette/components/ClientDivisionSelector/ClientDivisionSelector";
import ErrorAlert from "shared/UI/Alerts/ErrorAlert";
import { FormSubmitButton } from "../../UIPalette/components/Form/FormSubmitButton";
import Input from "@brighthr/component-input";
import { TargetGroupTypeAhead } from "../../UIPalette/components/TargetGroupTypeAhead/TargetGroupTypeAhead";
import Textarea from "@brighthr/component-textarea";
import { getServerErrorMessage } from "shared/utils/serverError";
import { toastNotify } from "shared/UI/Toaster/Toaster";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type Inputs = {
  sendTo: string;
  clientDivision: { clientId: string; divisionId: string };
  messageTitle: string;
  content: string;
  targetGroup: TargetGroupResponseModel;
};

export const PushNotifications = () => {
  const { t } = useTranslation();
  const [showClientDivision, setShowClientDivision] = useState(false);
  const [showTargetGroups, setShowTargetGroups] = useState(false);
  const [sendingNotification, setSendingNotification] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const selectAllUsers = () => {
    setShowClientDivision(false);
    setShowTargetGroups(false);
  };

  const selectSpecificClient = () => {
    setShowClientDivision(true);
    setShowTargetGroups(false);
  };

  const selectSpecificTargetGroup = () => {
    setShowClientDivision(false);
    setShowTargetGroups(true);
  };

  const { trigger: sendPushTrigger } = useSendPushNotificationFunctionRequest();

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    trigger: validationTrigger,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      sendTo: "allUsers",
      messageTitle: "",
      content: "",
      clientDivision: undefined,
    },
  });

  const onSubmit = async (inputs: Inputs) => {
    const isFormValid = await validationTrigger();
    if (isFormValid) {
      setErrorMessage("");
      setSendingNotification(true);
      try {
        await sendPushTrigger({
          body: {
            title: inputs.messageTitle,
            content: inputs.content,
            clientId: inputs.clientDivision?.clientId,
            divisionId: inputs.clientDivision?.divisionId,
            targetGroupId: inputs.targetGroup?.id,
          },
        });
        toastNotify(t("pushNotifications.successToast"), "success");
        reset();
        selectAllUsers();
      } catch (error: any) {
        setErrorMessage(getServerErrorMessage(error, t("pushNotifications.requestErrorMessage")));
      } finally {
        setSendingNotification(false);
      }
    }
  };

  return (
    <div>
      <h2 className="mb-4 text-black">{t("pushNotifications.title")}</h2>
      <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name={"sendTo"}
          control={control}
          render={({ field: { onChange } }) => (
            <>
              <label>{t("pushNotifications.sendToTitle")}</label>
              <div onChange={onChange} className="flex items-center px-3 space-x-2">
                <input
                  type="radio"
                  id="sendToAllUsers"
                  name="sendTo"
                  value="allUsers"
                  checked={getValues("sendTo") === "allUsers"}
                  onChange={selectAllUsers}
                />
                <label htmlFor="sendToAllUsers">{t("pushNotifications.sendTo.allUsers")}</label>

                <input
                  type="radio"
                  id="sendToSpecificClient"
                  name="sendTo"
                  value="specificClient"
                  checked={getValues("sendTo") === "specificClient"}
                  onChange={selectSpecificClient}
                />
                <label htmlFor="sendToSpecificClient">{t("pushNotifications.sendTo.specificClient")}</label>

                <input
                  type="radio"
                  id="sendToSpecificTargetGroup"
                  name="sendTo"
                  value="specificTargetGroup"
                  checked={getValues("sendTo") === "specificTargetGroup"}
                  onChange={selectSpecificTargetGroup}
                />
                <label htmlFor="sendToSpecificTargetGroup">{t("pushNotifications.sendTo.specificTargetGroup")}</label>
              </div>
            </>
          )}
        />

        {showClientDivision && (
          <Controller
            name={"clientDivision"}
            control={control}
            rules={{ validate: ({ clientId }) => !!clientId }}
            render={({ field: { onChange } }) => (
              <>
                <ClientDivisionSelector className="mt-2" onSelection={onChange} />
                {errors.clientDivision && <ErrorAlert content={t("pushNotifications.clientRequiredError")} />}
              </>
            )}
          />
        )}

        {showTargetGroups && (
          <Controller
            name={"targetGroup"}
            control={control}
            rules={{ validate: (targetGroup) => !!targetGroup }}
            render={({ field: { onChange } }) => (
              <>
                <TargetGroupTypeAhead className="mt-2" onTargetGroupSelected={onChange} />
                {errors.targetGroup && <ErrorAlert content={t("pushNotifications.targetGroupRequiredError")} />}
              </>
            )}
          />
        )}

        <div className="pt-5">
          <Controller
            name={"messageTitle"}
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <>
                <Input
                  type="text"
                  id="title"
                  aria-label={t("pushNotifications.messageTitle")}
                  onChange={onChange}
                  value={value}
                  autoComplete="disabled"
                  placeholder={t("pushNotifications.messageTitle")}
                />
                {errors.messageTitle && <ErrorAlert content={t("pushNotifications.messageTitleErrorMessage")} />}
              </>
            )}
          />
        </div>

        <div className="pt-5">
          <Controller
            name={"content"}
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <>
                <Textarea
                  id="title"
                  aria-label={t("pushNotifications.messageContent")}
                  onChange={onChange}
                  rows={8}
                  placeholder={t("pushNotifications.messageContent")}
                  value={value}
                />
                {errors.content && <ErrorAlert content={t("pushNotifications.messageContentErrorMessage")} />}
              </>
            )}
          />
        </div>

        <div className="flex pt-10 mt-2">
          <FormSubmitButton
            text={t("pushNotifications.sendButton")}
            onClick={() => {
              onSubmit(getValues());
            }}
            disabled={sendingNotification}
          />
        </div>
      </form>
      <ErrorAlert content={errorMessage} />
    </div>
  );
};
