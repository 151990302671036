import { hasAuthParams, useAuth as authContext } from "react-oidc-context";
import { SigninRedirectArgs, SignoutRedirectArgs } from "oidc-client-ts";

export type UseAuthResult = {
  isAuthenticated: boolean;
  isLoading: boolean;
  signinRedirect: (args?: SigninRedirectArgs) => Promise<void>;
  shouldAuthenticate: boolean;
  activeNavigator?: "signinRedirect" | "signinPopup" | "signinSilent" | "signoutRedirect" | "signoutPopup" | "signoutSilent";
  token: string | undefined;
  hasError: boolean;
  signoutRedirect(args?: SignoutRedirectArgs): Promise<void>;
};

export const useAuth = (): UseAuthResult => {
  const { isAuthenticated, isLoading, activeNavigator, signinRedirect, user, error, signoutRedirect, settings } = authContext();

  const shouldAuthenticate = !hasAuthParams() && !isAuthenticated && !activeNavigator && !isLoading;

  settings.silentRequestTimeoutInSeconds = 0.75;

  return {
    isAuthenticated,
    isLoading,
    signinRedirect,
    shouldAuthenticate,
    activeNavigator,
    token: user?.access_token,
    hasError: !!error,
    signoutRedirect,
  };
};
