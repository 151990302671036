import Input, { InputProps } from "@brighthr/component-input";
import classNames from "classnames";
import React, { ChangeEvent, FocusEvent, ForwardedRef, HTMLInputTypeAttribute, KeyboardEventHandler, useState } from "react";
import "./LabelledInput.css";

export type LabelledInputProps = {
  label: string;
  type: HTMLInputTypeAttribute;
  step?: number;
  min?: number;
  autoFocus?: boolean;
  selectOnFocus?: boolean;
  onChange: (value: string) => void;
  onKeyUp?: KeyboardEventHandler<HTMLInputElement>;
  hasError?: boolean;
  units?: string;
};

const handleFocus = (event: FocusEvent<HTMLInputElement, Element>, inputType: HTMLInputTypeAttribute) => {
  switch (inputType) {
    case "time":
      return event.target.showPicker();
    default:
      return event.target.select();
  }
};

export const LabelledInput = React.forwardRef(
  (
    { selectOnFocus, onChange, label, hasError, units, onKeyUp, value, ...props }: LabelledInputProps & Omit<InputProps, keyof LabelledInputProps>,
    _ref?: ForwardedRef<LabelledInputProps & Omit<InputProps, keyof LabelledInputProps>>
  ) => {
    const [inputValue, setInputValue] = useState(value ?? "");

    const inputProps: InputProps = {
      ...props,
      onChange: onChangedHandler,
      onKeyUp,
      value,
      "aria-label": label,
      placeholder: label,
      error: hasError,
      onFocus: selectOnFocus ? (e) => handleFocus(e, props.type) : undefined,
    };

    function onChangedHandler({ target: { value } }: ChangeEvent<HTMLInputElement>) {
      if (onChange !== undefined) {
        setInputValue(value);
        onChange(value);
      }
    }

    return (
      <div className={classNames("relative", "labelled", { "has-error": hasError })}>
        <Input {...inputProps} label="" />
        {units && (
          <div className="absolute right-8 top-3">
            <span className="text-grey">{units}</span>
          </div>
        )}
        {(inputValue || !props.placeholder) && <span className="text-grey absolute top-0.5 left-4 text-xs select-none">{label}</span>}
      </div>
    );
  }
);
