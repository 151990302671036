import "shared/extensions/date";

import { ExportReport } from "./ExportReport";
import { GroupingValues } from "../../../UIPalette/components/Reports/ReportConstants";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

type ReportProps = {
  title: string;
  grouping?: string;
};

export const Report = ({ title, grouping, children }: ReportProps & PropsWithChildren) => {
  const { t } = useTranslation();
  let groupName;
  switch (grouping) {
    case GroupingValues.Gender.toString():
      groupName = t("dashboard.grouping.byGender");
      break;
    case GroupingValues.AgeRange.toString():
      groupName = t("dashboard.grouping.byAgeRange");
      break;
    default:
      groupName = "";
      break;
  }

  return (
    <>
      <div className="pt-4 pl-1 pr-8 flex flex-row justify-between ml-2 mr-[6%]">
        <h4>{`${title} ${groupName}`}</h4>
        <ExportReport />
      </div>
      <div className="flex flex-row justify-evenly pt-4 mx-[6%]">{children}</div>
    </>
  );
};
