import { AdminUserState } from "core/state/adminUserState";
import Avatar from "@brighthr/component-avatar";
import { Hideable } from "shared/UI/Hideable/Hideable";
import { LogoutButton } from "../../../Views/LogoutButton/LogoutButton";
import StylableButton from "shared/UI/Buttons/StylableButton";
import { getChangePasswordUrl } from "shared/core/hooks/getChangePasswordUrl";
import useComponentVisible from "shared/UI/Hooks/useComponentVisible";
import { useRedirect } from "shared/core/hooks/useRedirect";
import { useTranslation } from "react-i18next";

export type UserProfileProps = {
  areDetailsVisible: boolean;
};

export const UserProfile = (props: UserProfileProps) => {
  const { t } = useTranslation();
  const user = AdminUserState.get();
  const userFullName = `${user?.forenames} ${user?.surname}`;

  const { targetAreaRef, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const changePasswordUrl = getChangePasswordUrl(
    process.env.REACT_APP_AUTH_AUTHORITY!,
    process.env.REACT_APP_AUTH_CLIENT_ID!,
    `${window.location.origin}/auth`
  );

  const redirectHook = useRedirect();

  return (
    <>
      <Hideable hidden={!isComponentVisible}>
        <div
          ref={targetAreaRef}
          className="absolute flex flex-col w-48 overflow-hidden bg-white border-2 border-solid rounded shadow-sm border-lighter-grey left-5 bottom-24 z-OVERLAY"
        >
          <StylableButton
            textButton={true}
            text={t("userProfile.changePassword")}
            className="[&>*]:p-2 text-sm text-sky-grey hover:bg-l-blue h-full transition-all"
            onClick={() => redirectHook(changePasswordUrl)}
            color={"primary"}
            fullWidth
          />

          <LogoutButton className="[&>*]:p-2 text-sm text-sky-grey hover:bg-l-red h-full transition-all bright-button" color={"primary"} fullWidth />
        </div>
      </Hideable>
      <button
        className="flex flex-row transition-all user-profile hover:bg-l-blue group"
        onClick={() => {
          setIsComponentVisible(true);
        }}
        aria-label={t("userProfile.ariaOpenAccountSettings")}
      >
        <div className={`${props.areDetailsVisible ? "w-1/4 p-4" : "p-1"}`}>
          <Avatar name={userFullName} imageHref={process.env.PUBLIC_URL + "/default-user-profile.png"} size="base" />
        </div>
        <Hideable hidden={!props.areDetailsVisible}>
          <div className="grid flex-col justify-start w-3/4 pt-4 pl-4 items-left">
            <span className="text-sm font-bold text-d-blue">{userFullName}</span>
            <span className="text-sm text-l-grey group-hover:text-black">{t("userProfile.editAccountDetails")}</span>
          </div>
        </Hideable>
      </button>
    </>
  );
};
