import { t } from "i18next";
import "./Paginator.css";
import Button from "@brighthr/component-button";
import { Hideable } from "shared/UI/Hideable/Hideable";

type PaginatorProps = {
  page: number;
  pageSize: number;
  total: number;
  onChanged: Function;
};

export const Paginator = ({ page, pageSize, total, onChanged }: PaginatorProps) => {
  const pageCount = Math.ceil(total / pageSize);
  const ellipsis = "...";

  const buildPageArray = () => {
    const maxDisplayableLength = 5;

    switch (true) {
      case pageCount <= maxDisplayableLength:
        return Array.from({ length: pageCount }, (_, i) => i + 1);
      case page - 2 <= 1:
        return [1, 2, 3, 4, ellipsis, pageCount];
      case page + 2 >= pageCount:
        return [1, ellipsis, pageCount - 3, pageCount - 2, pageCount - 1, pageCount];
      default:
        return [1, ellipsis, page - 1, page, page + 1, ellipsis, pageCount];
    }
  };

  return (
    <Hideable hidden={isNaN(pageCount) || pageCount <= 0}>
      <div className="flex flex-row ml-8">
        <Button className="page-link" aria-label={t("pagination.previousPage")} disabled={page === 1} onClick={() => onChanged(page - 1)} text="<" />
        {buildPageArray().map((p, i) => (
          <div key={i}>
            {p === ellipsis ? (
              <Button className="page-link" disabled text={ellipsis} />
            ) : (
              <Button className={p === page ? "page-link active-page-link" : "page-link"} onClick={() => onChanged(p)} text={p.toString()} />
            )}
          </div>
        ))}
        <Button
          className="page-link"
          aria-label={t("pagination.nextPage")}
          disabled={page === pageCount}
          onClick={() => onChanged(page + 1)}
          text=">"
        />
      </div>
    </Hideable>
  );
};
