import { t } from "i18next";
import { ReactElement, useState } from "react";
import { PageHeader } from "../../../UIPalette/components/PageHeader/PageHeader";
import { useDeleteWisdomUserFunctionRequest, useListWisdomUsersFunctionRequest, WisdomUserResponseModel } from "shared/request/myHealthyAdvantageApi";
import { ListWisdomUsersOrderBy } from "./ListWisdomUsersOrderBy";
import { OrderByDirection, RoleKeys } from "core/Constants";
import { RowMenuItem, Table, TableHeader, TableOrdering, TablePaging } from "../../../UIPalette/components/Table/Table";
import Input from "@brighthr/component-input";
import { useDebounce } from "shared/core/hooks/useDebounce";
import { ClientDivisionSelection, ClientDivisionSelector } from "../../../UIPalette/components/ClientDivisionSelector/ClientDivisionSelector";
import { Hideable } from "shared/UI/Hideable/Hideable";
import { useAdminUserContext } from "core/state/AdminUserContext";
import { toastNotify } from "shared/UI/Toaster/Toaster";
import { ConfirmationModal } from "shared/UI/Modal/ConfirmationModal/ConfirmationModal";

export const ViewWisdomUsers = (): ReactElement => {
  const { isAuthorised, user } = useAdminUserContext();
  const [clientDivisionFilter, setClientDivisionFilter] = useState<ClientDivisionSelection>();
  const [searchFilter, setSearchFilter] = useState<string>();
  const debouncedSearchFilter = useDebounce(searchFilter, 500);
  const [paging, setPaging] = useState<TablePaging>({ current: 1, size: 10 });
  const [ordering, setOrdering] = useState<TableOrdering>({
    column: ListWisdomUsersOrderBy.CreatedDateUtc,
    direction: OrderByDirection.Descending,
  });
  const [userToDelete, setUserToDelete] = useState<WisdomUserResponseModel>();
  const { trigger: deleteUser } = useDeleteWisdomUserFunctionRequest();
  const { data: usersResponse, mutate } = useListWisdomUsersFunctionRequest({
    page: paging.current,
    perPage: paging.size,
    orderBy: ordering.column,
    orderByDirection: ordering.direction,
    keyword: debouncedSearchFilter,
    clientId: clientDivisionFilter?.clientId,
    divisionId: clientDivisionFilter?.divisionId,
  });

  const tableHeaders: TableHeader[] = [
    { text: t("userManagement.table.nameHeading"), orderByValue: ListWisdomUsersOrderBy.Forenames },
    {
      text: t("userManagement.table.surnameHeading"),
      orderByValue: ListWisdomUsersOrderBy.Surname,
    },
    ...(!user?.client
      ? [
          {
            text: t("userManagement.table.clientHeading"),
            orderByValue: ListWisdomUsersOrderBy.Client,
          },
        ]
      : []),
    ...(!user?.division
      ? [
          {
            text: t("userManagement.table.divisionHeading"),
            orderByValue: ListWisdomUsersOrderBy.Division,
          },
        ]
      : []),
    { text: t("userManagement.table.emailHeading"), orderByValue: ListWisdomUsersOrderBy.Email },
    {
      text: t("userManagement.table.creationDateHeading"),
      orderByValue: ListWisdomUsersOrderBy.CreatedDateUtc,
    },
  ];

  const mapTableData = () =>
    usersResponse?.records?.map(({ forenames, surname, email, client, division, createdDateUtc }) => {
      return {
        forenames,
        surname,
        ...(!user?.client && { client: client?.name }),
        ...(!user?.division && { division: division?.name }),
        email,
        createdDate: String.formatDateTime(createdDateUtc),
      };
    });

  const buildRowMenuItems = (): RowMenuItem[] =>
    isAuthorised({ permissions: ["delete-users"] })
      ? [
          {
            title: t("table.deleteTitle"),
            ariaLabel: t("table.ariaDeleteRow"),
            onClick: (row: number) => setUserToDelete(getUserFromRow(row)),
          },
        ]
      : [];

  const onDeleteUser = async () => {
    try {
      await deleteUser({ query: { id: userToDelete!.id } });
      await mutate();
      toastNotify(t("userManagement.successfulDelete"), "success");
    } catch {
      toastNotify(t("userManagement.failedDelete"), "error");
    } finally {
      setUserToDelete(undefined);
    }
  };

  const getUserFromRow = (row: number) => usersResponse?.records?.at(row);

  return (
    <>
      <PageHeader title={t("userManagement.navBarTitle")} subtitle={t("userManagement.subtitle")} />
      <Hideable hidden={!isAuthorised({ role: RoleKeys.HaAdmin })}>
        <ClientDivisionSelector onSelection={(clientDivisionFilters) => setClientDivisionFilter(clientDivisionFilters)} />
      </Hideable>
      <div className="w-2/5 my-2 content-center">
        <Input
          aria-label={t("userManagement.searchInputLabel")}
          onChange={(a) => setSearchFilter(a.target.value)}
          rightIconName="search"
          placeholder={t("userManagement.searchPlaceholder")}
        ></Input>
      </div>
      <Table
        headers={tableHeaders}
        dataRows={mapTableData() ?? []}
        rowMenuItems={buildRowMenuItems()}
        orderedByColumn={ordering.column}
        orderedDirection={ordering.direction}
        currentPage={paging.current}
        pageSize={paging.size}
        totalDataCount={usersResponse?.metadata?.totalCount ?? 0}
        onOrderingChange={setOrdering}
        onPagingChange={setPaging}
      />
      {userToDelete && (
        <ConfirmationModal
          title={t("userManagement.deleteModal.title")}
          message={t("userManagement.deleteModal.message", { userEmail: userToDelete?.email })}
          cancelButtonText={t("userManagement.deleteModal.cancel")}
          confirmButtonText={t("userManagement.deleteModal.confirm")}
          onCancel={() => setUserToDelete(undefined)}
          onConfirm={onDeleteUser}
        />
      )}
    </>
  );
};
