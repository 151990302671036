import { t } from "i18next";
import { Controller, useForm } from "react-hook-form";
import { PageHeader } from "../../../UIPalette/components/PageHeader/PageHeader";
import { Form } from "../../../UIPalette/components/Form/Form";
import ErrorAlert from "shared/UI/Alerts/ErrorAlert";
import Input from "@brighthr/component-input";
import { ClientTypeAhead } from "../../../UIPalette/components/ClientTypeAhead/ClientTypeAhead";
import {
  ClientLookupModel,
  TargetGroupResponseModel,
  UpsertTargetGroupCommand,
  useUpsertTargetGroupFunctionRequest,
} from "shared/request/myHealthyAdvantageApi";
import { toastNotify } from "shared/UI/Toaster/Toaster";
import { useLocation, useNavigate } from "react-router-dom";
import { TargetGroupPreviewCard } from "./Components/TargetGroupPreviewCard";
import { useState } from "react";
import { ConfirmationModal } from "shared/UI/Modal/ConfirmationModal/ConfirmationModal";

export enum TargetGroupPageType {
  Create,
  Edit,
}

type TargetedClient = {
  id: string;
  name: string;
};

type Inputs = {
  targetGroupName: string;
  clients: TargetedClient[];
};

export const TargetGroup = ({ pageType }: { pageType: TargetGroupPageType }) => {
  const { state } = useLocation();
  const targetGroupData = state as TargetGroupResponseModel;
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { trigger: upsertTargetGroupTrigger, error: upsertTargetGroupError, reset } = useUpsertTargetGroupFunctionRequest();
  var navigateHook = useNavigate();

  const pageIsEditing = pageType === TargetGroupPageType.Edit;

  const {
    control,
    formState: { errors },
    getValues,
    trigger,
    setValue,
    watch,
  } = useForm<Inputs>({
    defaultValues: {
      targetGroupName: pageIsEditing ? targetGroupData?.name : "",
      clients: pageIsEditing ? targetGroupData?.clients : [],
    },
  });

  const selectedClients = getValues("clients");

  const onConfirmSubmit = async () => {
    if (pageIsEditing) {
      setShowConfirmationModal(true);
    } else {
      await onSubmit();
    }
  };

  const onSubmit = async () => {
    const upsertCommand: UpsertTargetGroupCommand = {
      id: pageIsEditing ? targetGroupData.id : undefined,
      clientIds: getValues("clients").map((client) => client.id),
      name: getValues("targetGroupName"),
    };

    try {
      const response = await upsertTargetGroupTrigger({ body: upsertCommand });
      toastNotify(
        pageIsEditing
          ? t("targetGroups.targetGroup.edit.successToast", { targetGroup: response?.data.name })
          : t("targetGroups.targetGroup.create.successToast", { targetGroup: response?.data.name }),
        "success"
      );
      navigateHook("/targetgroups");
    } catch {
      toastNotify(pageIsEditing ? t("targetGroups.targetGroup.edit.failedToast") : t("targetGroups.targetGroup.create.failedToast"), "error");
    }
  };

  const canAddClient = (client: ClientLookupModel | undefined) =>
    client && selectedClients.every((selectedClient) => selectedClient.id !== client.id);

  return (
    <div>
      <PageHeader
        title={pageType === TargetGroupPageType.Create ? t("targetGroups.targetGroup.create.title") : t("targetGroups.targetGroup.edit.title")}
        subtitle={t("targetGroups.targetGroup.subtitle")}
      />
      <Form
        className="mt-4"
        onSubmit={onConfirmSubmit}
        onCancel={() => navigateHook("/targetgroups")}
        validationTrigger={trigger}
        submitButtonText={pageType === TargetGroupPageType.Create ? t("form.defaults.submitButton") : t("form.defaults.saveButton")}
      >
        <div className="flex gap-4">
          <div className="basis-1/2">
            <Controller
              name="targetGroupName"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <div>
                  <Input
                    id="targetGroupName"
                    aria-label={t("targetGroups.targetGroup.ariaName")}
                    placeholder={t("targetGroups.targetGroup.namePlaceholder")}
                    type="text"
                    onChange={(e) => {
                      onChange(e);
                      reset();
                    }}
                    defaultValue={pageIsEditing ? targetGroupData?.name : value}
                  />
                  {errors.targetGroupName?.type === "required" && <ErrorAlert content={t("targetGroups.targetGroup.nameRequiredError")} />}
                  {upsertTargetGroupError && upsertTargetGroupError.response?.status === 409 && (
                    <ErrorAlert content={t("targetGroups.targetGroup.targetGroupAlreadyExists")} />
                  )}
                </div>
              )}
            />
            <Controller
              name="clients"
              control={control}
              rules={{ validate: () => selectedClients.length > 0 }}
              render={({ field: { onChange } }) => (
                <div className="mt-4">
                  <label className="font-bold">
                    {t("targetGroups.targetGroup.selectClientsLabel")}
                    <ClientTypeAhead
                      placeholder="Search clients..."
                      onClientSelected={(client) =>
                        canAddClient(client) &&
                        onChange([
                          ...selectedClients,
                          {
                            id: client!.id,
                            name: client!.name,
                          },
                        ])
                      }
                      autoFill={false}
                    />
                    {errors.clients?.type === "validate" && <ErrorAlert content={t("targetGroups.targetGroup.clientRequiredError")} />}
                  </label>
                </div>
              )}
            />
          </div>
          <div className="basis-1/2">
            <TargetGroupPreviewCard
              name={watch().targetGroupName}
              clients={watch().clients}
              onRemove={(clientId) =>
                setValue(
                  "clients",
                  selectedClients.filter((client) => client.id !== clientId)
                )
              }
            />
          </div>
        </div>
      </Form>

      {showConfirmationModal && (
        <ConfirmationModal
          title={t("targetGroups.targetGroup.edit.confirmation.modalTitle")}
          message={t("targetGroups.targetGroup.edit.confirmation.modalMessage")}
          cancelButtonText={t("targetGroups.targetGroup.edit.confirmation.modalDismissTitle")}
          confirmButtonText={t("targetGroups.targetGroup.edit.confirmation.modalConfirmTitle")}
          onCancel={() => {
            setShowConfirmationModal(false);
          }}
          onConfirm={onSubmit}
        />
      )}
    </div>
  );
};
