import { Suggestion, Typeahead } from "../Typeahead/Typeahead";
import { TargetGroupResponseModel, useListTargetGroupsFunctionRequest } from "shared/request/myHealthyAdvantageApi";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDebounce } from "shared/core/hooks/useDebounce";
import { ValueCallback } from "shared/core/types/callbacks";

type TargetGroupTypeAheadProps = React.HTMLAttributes<HTMLDivElement> & {
  onTargetGroupSelected: ValueCallback<TargetGroupResponseModel | undefined>;
  readOnly?: boolean;
  autoFill?: boolean;
};

export const TargetGroupTypeAhead = ({ onTargetGroupSelected, readOnly, placeholder, autoFill = true, ...props }: TargetGroupTypeAheadProps) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const { data } = useListTargetGroupsFunctionRequest(
    { nameFilter: debouncedSearchTerm!, orderBy: 0, orderByDirection: 0, page: 1, perPage: 50 },
    { shouldFetch: readOnly !== true }
  );

  const mapTargetGroupSuggestions = () =>
    data?.records?.map((e) => ({ value: e, displayText: e.name } as Suggestion<TargetGroupResponseModel>)) ?? [];

  useEffect(() => {
    onTargetGroupSelected(undefined);
  }, [debouncedSearchTerm, onTargetGroupSelected]);

  return (
    <div {...props}>
      <Typeahead
        placeholder={placeholder ?? t("targetGroups.typeAhead.placeholder")}
        suggestions={mapTargetGroupSuggestions()}
        onTextChanged={(e: string) => setSearchTerm(e)}
        onItemSelected={(suggestion) => onTargetGroupSelected(suggestion?.value!)}
        aria-label={t("targetGroups.typeAhead.ariaSearch")}
        readOnly={readOnly}
        initialText={searchTerm}
        autoFill={autoFill}
      />
    </div>
  );
};
