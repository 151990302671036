import { useTranslation } from "react-i18next";
import { PageHeader } from "../../UIPalette/components/PageHeader/PageHeader";
import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { ReactComponent as UploadIcon } from "shared/assets/Icons/upload.svg";
import StylableButton from "shared/UI/Buttons/StylableButton";
import { useImportClientsFunctionRequest } from "shared/request/myHealthyAdvantageApi";
import ErrorAlert from "shared/UI/Alerts/ErrorAlert";
import ViewLoading from "shared/UI/Spinners/ViewLoading";
import { toastNotify } from "shared/UI/Toaster/Toaster";
import { getServerErrorMessage } from "shared/utils/serverError";
import "./ImportClients.css";

export const ImportClients = () => {
  const { t } = useTranslation();
  const [csvFile, setCsvFile] = useState<File | undefined>(undefined);
  const [clientErrorMessage, setClientErrorMessage] = useState("");
  const [serverErrorMessage, setServerErrorMessage] = useState("");
  const { trigger: importCsvFile, isMutating: importing } = useImportClientsFunctionRequest();

  const fileTypes = ["CSV"];
  const mbLimit = 10;

  const handleChange = (file: File) => {
    reset();
    setCsvFile(file);
  };

  const onSizeError = () => {
    setServerErrorMessage("");
    setClientErrorMessage(t("importClients.errors.maxFileSize", { limit: mbLimit }));
  };

  const onTypeError = () => {
    setServerErrorMessage("");
    setClientErrorMessage(t("importClients.errors.invalidFileType"));
  };

  const importOnClick = async () => {
    setServerErrorMessage("");

    const formData = new FormData();
    formData.append("csvFile", csvFile!);

    try {
      const response = await importCsvFile({ body: formData });
      const recordCount = response?.data?.recordsProcessedCount ?? 0;
      toastNotify(t("importClients.successToast", { count: recordCount }), "success");
      reset();
    } catch (error: any) {
      setServerErrorMessage(getServerErrorMessage(error, t("importClients.errors.genericServerError")));
    }
  };

  const cancelOnClick = () => {
    reset();
  };

  const reset = () => {
    setClientErrorMessage("");
    setServerErrorMessage("");
    setCsvFile(undefined);
  };

  return (
    <>
      <PageHeader title={t("importClients.title")} subtitle={t("importClients.subtitle")} />
      <h4 className="mt-5 mb-2">{t("importClients.heading")}</h4>

      <div className="w-1/2" data-testid="upload-file-container">
        {importing ? (
          <div className="p-8 rounded-lg border-dashed border-2 border-sky-grey">
            <ViewLoading heightClass={"h-20"} />
          </div>
        ) : (
          <FileUploader
            handleChange={handleChange}
            onSizeError={onSizeError}
            onTypeError={onTypeError}
            name="file"
            types={fileTypes}
            maxSize={mbLimit}
            classes={"fileUploader"}
          >
            <button
              className="p-8 w-full rounded-lg border-dashed border-2 border-sky-grey hover:border-black transition-all"
              data-testid="file-select-button"
            >
              {csvFile === undefined ? (
                <div className="flex justify-center align-middle flex-col">
                  <UploadIcon className={"w-full mb-6 h-[4em]"} />
                  <div className="mb-4">{t("importClients.dropZoneText")}</div>
                  <div className="text-l-grey">{t("importClients.dragZoneFileLimitations", { limit: mbLimit })}</div>
                </div>
              ) : (
                <div>{t("importClients.dragZoneFileSelected", { filename: csvFile.name })}</div>
              )}
            </button>
          </FileUploader>
        )}
        <ErrorAlert content={clientErrorMessage} />
        <ErrorAlert content={serverErrorMessage} />
      </div>

      <div className={"flex pt-5"}>
        <StylableButton
          className={"form-button form-button-cancel mr-4"}
          color="accent"
          text={t("importClients.cancelButton")}
          fullWidth
          type="button"
          disabled={importing}
          onClick={cancelOnClick}
        />
        <StylableButton
          className={"form-button form-button-submit"}
          color="primary"
          text={t("importClients.importButton")}
          fullWidth
          disabled={csvFile === undefined || importing}
          onClick={importOnClick}
        />
      </div>
    </>
  );
};
